import "./index.scss";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowIcon from "../../../../assets/images/Acceron/Home/Fourth-Section/Arrow.png";
import AcceronEvents from "../../../../assets/images/Acceron/Home/Fourth-Section/acceron_events.png";
import EnhancingDrive from "../../../../assets/images/Acceron/Home/Fourth-Section/enhancing_drive.png";
import Friction from "../../../../assets/images/Acceron/Home/Fourth-Section/friction.png";
import LatestEvents from "../../../../assets/images/Acceron/Home/Fourth-Section/acceron_e.png";
import NewEvents from "../../../../assets/images/Acceron/Home/Fourth-Section/acceron_b.png";
import Sons from "../../../../assets/images/Acceron/Home/Fourth-Section/sons.png";
import { useTheme } from "../../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../../utils/colors";
import { getContrastColor } from "../../../../../utils/ColorUtils";
import { Link } from "react-router-dom";
import { get } from "../../../../../utils/api";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
    slidesToSlide: 1,
  },
};

function CarouselComponent(props: any) {
  useEffect(() => {
    getNews();
  }, []);

  const [data, setData] = React.useState<any>([]);

  const getNews = async () => {
    try {
      get("news", {}).then((res) => {
        console.log(res.data.data);
        setData(
          res.data.data.filter(
            (e: any) => e.status === "active" || e.status === "Active"
          )
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data);

  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  return (
    <>
      <Carousel
        ssr
        partialVisible={false}
        itemClass="image-item hidden md:block"
        responsive={responsive}
        deviceType={props.deviceType}
      >
        {data.slice(0, data.length + 1).map((item: any) => {
          return (
            <div className="fourth-section-carousel-card">
              <Card
                className="fourth-section-card"
                style={{
                  backgroundColor: primaryColor,
                  color: textColor === "light" ? "#fff" : "#000",
                  borderColor: secondaryColor,
                  maxWidth: "361px",
                  maxHeight: "466px",
                }}
              >
                <CardContent>
                  <div className="text-center" style={{ maxHeight: "60%" }}>
                    <div
                      style={{
                        height: "300px", // Adjust height as needed
                      }}
                    >
                      <img
                        src={item.image_url}
                        alt=""
                        className="w-100"
                        style={{
                          width: "100%",
                          height: "100%",
                          // maxHeight: "203px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                  <div className="fourth-section-card-text">
                    <h4 style={{ color: secondaryColor }}>
                      {item.news_title.length > 30
                        ? item.news_title.slice(0, 30) + "..."
                        : item.news_title}
                    </h4>
                    <p style={{ color: secondaryColor }}>
                      {item.news_content.length > 100
                        ? item.news_content.slice(0, 100) + "..."
                        : item.news_content}
                    </p>
                    <Button component={Link} to={item.news_url} target="_blank">
                      <span>Read More</span>
                      <img src={ArrowIcon} alt="" />
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          );
        })}
      </Carousel>

      <div className="block md:hidden">
        {data.slice(0, data.length + 1).map((item: any) => (
          <Card
            className="mobile-card"
            style={{
              backgroundColor: primaryColor,
              color: textColor === "light" ? "#fff" : "#000",
              borderColor: secondaryColor,
              marginBottom: "16px", // Add gap between cards
              maxWidth: "100%",
            }}
            key={item.news_url} // Ensure a unique key
          >
            <CardContent>
              <div className="text-center" style={{ maxHeight: "60%" }}>
                <div
                  style={{
                    height: "300px", // Adjust height as needed
                  }}
                >
                  <img
                    src={item.image_url}
                    alt=""
                    className="w-100"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              <div className="fourth-section-card-text">
                <h4 style={{ color: secondaryColor }}>
                  {item.news_title.length > 30
                    ? item.news_title.slice(0, 30) + "..."
                    : item.news_title}
                </h4>
                <p style={{ color: secondaryColor }}>
                  {item.news_content.length > 100
                    ? item.news_content.slice(0, 100) + "..."
                    : item.news_content}
                </p>
                <Button component={Link} to={item.news_url} target="_blank">
                  <span>Read More</span>
                  <img src={ArrowIcon} alt="" />
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );
}

export default CarouselComponent;
