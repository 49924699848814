import "./index.scss";

import React, { useState } from "react";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-black.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";

import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import CarouselComponent from "../../Home/Fourth-Section/CarouselComponent";

function NewsPageSection() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  return (
    <>
      <div
        className="row fourth-section-container news-section-container w-100"
        style={{
          backgroundColor: primaryColor,
          color: textColor === "light" ? "#fff" : "#000",
        }}
      >
        <div className="col-xs-12 fourth-section-main">
          <div className="d-flex justify-content-start align-items-center fourth-section-heading">
            <h2 style={{ color: secondaryColor }}>Latest in News</h2>
            {textColor === "light" ? (
              <img
                src={HeadingLine}
                alt=""
                className="img-fluid headline-image"
              />
            ) : (
              <img
                src={HeadingLineWhite}
                alt=""
                className="img-fluid headline-image"
              />
            )}
          </div>
          <div className="d-flex justify-content-between align-items-start fourth-section-features-container">
            <div className="col-xs-12 col-md-2 col-lg-2">
              <div className="d-flex flex-column justify-content-center align-items-start fourth-section-features">
                <Divider
                  className="fourth-section-divider"
                  style={{ backgroundColor: secondaryColor }}
                />
                <Button>
                  <h4 className="text-nowrap" style={{ color: secondaryColor }}>
                    Product & Innovation
                  </h4>
                </Button>
                <Divider
                  className="fourth-section-divider"
                  style={{ backgroundColor: secondaryColor }}
                />
                <Button>
                  <h4 style={{ color: secondaryColor }}>Events</h4>
                </Button>
                <Divider
                  className="fourth-section-divider"
                  style={{ backgroundColor: secondaryColor }}
                />
                <Button>
                  <h4 style={{ color: secondaryColor }}>Group & Partners</h4>
                </Button>
                <Divider
                  className="fourth-section-divider"
                  style={{ backgroundColor: secondaryColor }}
                />
                <Button>
                  <h4 style={{ color: secondaryColor }}>Business & Services</h4>
                </Button>
              </div>
            </div>
            <div className="d-md-flex d-block justify-content-between align-items-center col-xs-12 col-md-10 col-lg-10">
              <CarouselComponent deviceType={"desktop"} />
            </div>
          </div>
        </div>
      </div>
      <Divider sx={{ bgcolor: "#FFFFFF", width: "100%" }} />
    </>
  );
}

export default NewsPageSection;
