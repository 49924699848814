import "./index.scss";
import DividerLine from "../../../assets/images/Acceron/Second-Section/divider-line.png";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-black.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import careers_img from "../../../assets/images/Acceron/careers/careers_img.png";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import file from "react-player/file";
import Input from "@mui/material/Input";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import { FormControl, InputBase } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { awsupload, post } from "../../../../utils/api";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import SnackbarAlert from "../../../../common/sanckbar";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "warning",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFF",
    },
    "&:hover fieldset": {
      borderColor: "#FFF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "warning",
    },
    "& .MuiInputBase-input": {
      color: "white", // Change the text color to white
    },
  },
});

function CareersFirstSection() {
  const [headingLine, setHeadingLine] = useState(HeadingLine);

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  useEffect(() => {
    if (textColor === "light") {
      setHeadingLine(HeadingLine);
    } else {
      setHeadingLine(HeadingLineWhite);
    }
  }, [textColor]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    resume: "",
    resume_id: "",
    resume_url: "",
  });

  const [loadingLogo, setLoadingLogo] = useState(false);

  const [formErrors, setFormErrors] = useState({}); // State to manage form errors

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      // Assuming `post` is an asynchronous function that sends data to the backend
      const response = await post("career_enquiry", formData);
      console.log(response);
      console.log(response.Status);
      // if (response.status === 200 || response.status === 201) {
      if (response) {
        alert("Submitted Successfully", "success");
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          resume: "",
          resume_id: "",
          resume_url: "",
        });
      } else {
        alert("Submission Failed", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.", "error");
    }
  };

  // const fileInputRef = useRef<any>(null);

  const uploadFile = async (event: any) => {
    // let file_id: any;
    let folder_name = "career_enquiry";
    setLoadingLogo(true);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    // setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    let queryParams = {
      folder_name: folder_name,
      selectedFile: selectedFile.name,
      file_type: fileType,
    };
    // let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", selectedFile, selectedFile.name);
    awsupload("uploadResume", data1, queryParams)
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          const newData = {
            ...formData,
            resume_url:
              res.data.resume_url + "?timestamp=" + new Date().getTime(),
            resume_id: res.data.resume_id,
          };
          setFormData(newData);
          console.log("newData", newData);
          setLoadingLogo(false);
          alert("File Uploaded Successfully", "success");
        }
      })
      .catch((err: any) => {
        console.error(err);
        alert("File Upload Failed", "error");
      });
  };

  const handleButtonClick = () => {
    // Trigger the file input click event
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div
        className="container-fluid career-first-section-container"
        style={{
          backgroundColor: primaryColor,
          color: textColor === "light" ? "#fff" : "#000",
        }}
      >
        <div className="row contact-first-section-row">
          <div className="head-and-para">
            <h6 style={{ color: secondaryColor }}>
              Join Our <span>Team</span>
            </h6>
            <p style={{ color: secondaryColor }}>
              In search of a new challenge with a friendly family atmosphere?
              We're seeking top talent to maintain our leadership in the field.
              Join us to develop your skills and personality to the fullest.
            </p>
          </div>
        </div>
        <div className="row careers-infornation-row">
          <div className="col-lg-4 col-sm-12 careers-infornation-image-row">
            <img src={careers_img} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className="row">
              <div className="d-flex justify-content-start align-items-center fourth-section-heading">
                <h2 style={{ color: secondaryColor }}>
                  Discover Jobs <span>At Acceron</span>
                </h2>
                <img
                  src={headingLine}
                  alt=""
                  className="headline-image img-fluid"
                />
              </div>
            </div>
            <div className="row careers-infornation-text">
              <p style={{ color: secondaryColor }}>
                Acceron invites you to explore the exciting opportunities we
                offer, whether you're launching your career or bringing your
                valuable work experience to the table
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="d-flex justify-content-start align-items-center fourth-section-heading">
              <h2 style={{ color: secondaryColor }}>
                Career <span>Portal</span>
              </h2>
              <img
                src={headingLine}
                alt=""
                className="img-fluid headline-image"
              />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row contact-second-section-row">
            <div className="col-lg-6 col-sm-12 contact-field-col">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                  "& .MuiTextField-root:hover": {
                    borderColor: secondaryColor,
                  },
                }}
              >
                <div>
                  <TextField
                    className="contact-box"
                    id="outlined-multiline-flexible"
                    label="First Name"
                    name="first_name"
                    multiline
                    maxRows={4}
                    color="warning"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    required
                    sx={{
                      " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                        {
                          color: secondaryColor,
                        },
                      " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          color: secondaryColor,
                          borderColor: secondaryColor,
                        },
                      " & .MuiInputLabel-root": {
                        color: secondaryColor,
                      },
                      " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: secondaryColor,
                        },
                      // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                      //   {
                      //     borderColor: secondaryColor,
                      //   },
                      // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                      //   {
                      //     borderColor: secondaryColor,
                      //   },
                    }}
                  />
                  {/* <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="First Name"
                  name="first_name"
                  multiline
                  maxRows={4}
                  color="warning"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  required
                  sx={{
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                  }}
                /> */}
                </div>
              </Box>
            </div>
            <div className="col-lg-6 col-sm-12 contact-field-col">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    className="career-box"
                    id="outlined-multiline-flexible"
                    label="Last Name"
                    name="last_name"
                    multiline
                    maxRows={4}
                    color="warning"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    required
                    sx={{
                      " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                        {
                          color: secondaryColor,
                        },
                      " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          color: secondaryColor,
                          borderColor: secondaryColor,
                        },
                      " & .MuiInputLabel-root": {
                        color: secondaryColor,
                      },
                      " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: secondaryColor,
                        },
                      // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                      //   {
                      //     borderColor: secondaryColor,
                      //   },
                      // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                      //   {
                      //     borderColor: secondaryColor,
                      //   },
                    }}
                  />
                </div>
              </Box>
            </div>
          </div>
          <div className="row contact-second-section-row">
            <div className="col-lg-6 col-sm-12 contact-field-col">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    className="career-box"
                    id="outlined-multiline-flexible "
                    label="Email Id"
                    name="email"
                    multiline
                    maxRows={4}
                    color="warning"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    sx={{
                      " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                        {
                          color: secondaryColor,
                        },
                      " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          color: secondaryColor,
                          borderColor: secondaryColor,
                        },
                      " & .MuiInputLabel-root": {
                        color: secondaryColor,
                      },
                      " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: secondaryColor,
                        },
                      // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                      //   {
                      //     borderColor: secondaryColor,
                      //   },
                      // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                      //   {
                      //     borderColor: secondaryColor,
                      //   },
                    }}
                  />
                </div>
              </Box>
            </div>
            <div className="col-lg-6 col-sm-12 contact-field-col">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    className="career-box"
                    id="outlined-multiline-flexible"
                    label="Phone Number"
                    multiline
                    name="phone_number"
                    color="warning"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                    sx={{
                      " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                        {
                          color: secondaryColor,
                        },
                      " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          color: secondaryColor,
                          borderColor: secondaryColor,
                        },
                      " & .MuiInputLabel-root": {
                        color: secondaryColor,
                      },
                      " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: secondaryColor,
                        },
                      // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                      //   {
                      //     borderColor: secondaryColor,
                      //   },
                      // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                      //   {
                      //     borderColor: secondaryColor,
                      //   },
                    }}
                  />
                </div>
              </Box>
            </div>
          </div>
          <div className="row contact-second-section-row">
            <div className="col-12 resume-upload-col">
              {/* <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
          noValidate
          autoComplete="off"
        > */}
              <p
                style={{
                  color: "#000000",
                  marginBottom: 0,
                  marginRight: "1rem",
                }}
              >
                Upload your resume
              </p>
              <FormControl>
                <Paper
                  className="resume-upload"
                  component="form"
                  sx={{
                    // p: "2px 4px",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label htmlFor="file-upload">
                    <Button
                      variant="contained"
                      component="span"
                      color="warning"
                      // onClick={handleButtonClick}
                      sx={{
                        color: secondaryColor,
                        " & :hover": {
                          backgroundColor: secondaryColor,
                        },
                      }}
                    >
                      <InputBase
                        id="file-upload"
                        type="file"
                        inputProps={{ accept: ".docx,.pdf" }}
                        style={{ display: "none" }}
                        onChange={uploadFile}
                      />
                      Upload
                    </Button>
                  </label>
                </Paper>
              </FormControl>
              {/* </Box> */}
            </div>
          </div>

          <div className="row submit-btn-row">
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: secondaryColor,
                color: textColor,
                "&:hover": {
                  backgroundColor: "#E65100",
                  color: secondaryColor,
                },
              }}
            >
              <span style={{ color: secondaryColor }}>SUBMIT APPLICATION</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
export default CareersFirstSection;
