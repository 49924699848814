import React from "react";
import Navbar from "../Navbar/Navbar";
import FirstSection from "./Product-Categories";
import Footer from "../Footer";
import ProductVariety from "./Products";
import ProductDetails from "./Product-Details";
import SEO from "../SEO";

function ProductsDetailsPage() {
  return (
    <>
      <SEO
        title="Product Details"
        description="Product Details"
        keywords="product, acceron, efficiency, reliability, solutions"
        image="../../assets/images/Acceron/Products/GEAR OIL.png"
        url="https://www.acceron.co/products/*"
      />
      <Navbar />
      <ProductDetails />
      <Footer />
    </>
  );
}

export default ProductsDetailsPage;
