import "./index.scss";

import React, { useState } from "react";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-black.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import CarouselComponent from "./CarouselComponent";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import { Link } from "react-router-dom";

function FourthSection() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  return (
    <>
      <div
        className="row fourth-section-container w-100"
        style={{
          backgroundColor: primaryColor,
          color: textColor === "light" ? "#fff" : "#000",
        }}
      >
        <div className="col-xs-12 fourth-section-main">
          <div className="d-flex justify-content-start align-items-center fourth-section-heading">
            <h2 style={{ color: secondaryColor }}>Latest in News</h2>
            {textColor === "light" ? (
              <img
                src={HeadingLine}
                alt=""
                className="img-fluid headline-image"
              />
            ) : (
              <img
                src={HeadingLineWhite}
                alt=""
                className="img-fluid headline-image"
              />
            )}
          </div>
          <div className="d-flex justify-content-between align-items-start fourth-section-features-container">
            <div className="col-xs-12 col-md-2 col-lg-2">
              <div className="d-flex flex-column justify-content-center align-items-start fourth-section-features">
                <Link to={"newspage"}>
                  <Button>
                    <h4 style={{ color: secondaryColor }}>All News</h4>
                  </Button>
                </Link>

                <Divider
                  className="fourth-section-divider"
                  style={{ backgroundColor: secondaryColor }}
                />
                <Button>
                  <h4 className="text-nowrap" style={{ color: secondaryColor }}>
                    Product & Innovation
                  </h4>
                </Button>
                <Divider
                  className="fourth-section-divider"
                  style={{ backgroundColor: secondaryColor }}
                />
                <Button>
                  <h4 style={{ color: secondaryColor }}>Events</h4>
                </Button>
                <Divider
                  className="fourth-section-divider"
                  style={{ backgroundColor: secondaryColor }}
                />
                <Button>
                  <h4 style={{ color: secondaryColor }}>Group & Partners</h4>
                </Button>
                <Divider
                  className="fourth-section-divider"
                  style={{ backgroundColor: secondaryColor }}
                />
                <Button>
                  <h4 style={{ color: secondaryColor }}>Business & Services</h4>
                </Button>
              </div>
            </div>
            {window.matchMedia("(max-width: 464px)") ? (
              <div className="d-md-flex d-block justify-content-between align-items-center col-xs-12 col-md-9 col-lg-9">
                <CarouselComponent deviceType={"mobile"} />
              </div>
            ) : window.matchMedia("(max-width: 1024px)") ? (
              <div className="d-md-flex d-block justify-content-between align-items-center col-xs-12 col-md-9 col-lg-9">
                <CarouselComponent deviceType={"tablet"} />
              </div>
            ) : (
              <div className="d-md-flex d-block justify-content-between align-items-center col-xs-12 col-md-9 col-lg-9">
                <CarouselComponent deviceType={"desktop"} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Divider sx={{ bgcolor: "#FFFFFF", width: "100%" }} />
    </>
  );
}

export default FourthSection;
