import "./index.scss";
import DividerLine from "../../../assets/images/Acceron/Second-Section/divider-line.png";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import Dropsown_Icon from "../../../assets/images/Acceron//Header/arrow-left.png";
import careers_img from "../../../../assets/images/Acceron/careers/careers_img.png";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import Input from "@mui/material/Input";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Dropdown from "../../Dropdown/Dropdown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { get, post } from "../../../../utils/api";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";

function DealerFirstSection() {
  const [headingLine, setHeadingLine] = useState(HeadingLine);

  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  useEffect(() => {
    if (textColor === "light") {
      setHeadingLine(HeadingLine);
    } else {
      setHeadingLine(HeadingLineWhite);
    }
  }, [textColor]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    product_category: "",
    product_type: "",
    phone_number: "",
    message: "",
  });

  const [productCategory, setProductCategory] = useState<Array<any>>([]);
  const [products, setProducts] = useState<Array<any>>([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchProductCategory({}, 1, 20);
  }, []);

  const fetchProductCategory = (
    filter = {},
    page_no: number = 1,
    limit: number = 10
  ) => {
    get("product_category", {
      filter: filter,
      page: page_no,
      limit: limit,
    }).then((response) => {
      setProductCategory(
        response.data.data.filter(
          (e: any) => e.status === "active" || e.status === "Active"
        )
      );
      setTotalCount(response.data.totalCount);
    });
  };

  const [formErrors, setFormErrors] = useState({}); // State to manage form errors

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchProducts = (
    filter: any,
    page_no: number = 1,
    limit: number = 10
  ) => {
    get("product_variety", {
      filter: filter,
      page: page_no,
      limit: limit,
    }).then((response) => {
      setProducts(
        response.data.data.filter(
          (e: any) => e.status === "active" || e.status === "Active"
        )
      );
      setTotalCount(response.data.totalCount);
    });
  };

  const [selectedProductCategory, setSelectedProductCategory] = React.useState(
    {}
  );
  const handleProductCategorySelect = (event: any) => {
    let product_category = event.target.value;
    setSelectedProductCategory(product_category as Object);

    setFormData({
      ...formData, // Copy the existing formData
      product_category: product_category.product_category, // Update only the product_category field
    });

    let filter = [
      {
        key: "product_category_id",
        value: product_category.product_category_id,
        type: "Number",
      },
    ];
    fetchProducts(filter, 1, 20);
  };

  const [selectedProduct, setSelectedProduct] = React.useState({});
  const handleProductSelect = (event: any) => {
    let product = event.target.value;
    setSelectedProduct(product as Object);

    setFormData({
      ...formData, // Copy the existing formData
      product_type: product.product, // Update only the product_category field
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      // Assuming `post` is an asynchronous function that sends data to the backend
      const response = await post("product_enquiry", formData);
      console.log(response);
      console.log(response.Status);
      // if (response.status === 200 || response.status === 201) {
      if (response) {
        alert("Submitted Successfully");
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          product_category: "",
          product_type: "",
          phone_number: "",
          message: "",
        });
      } else {
        alert("Submission Failed");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div
      className="container-fluid dealer-first-section-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <div className="row dealer-first-section-row">
        <div className="d-flex justify-content-start align-items-center fourth-section-heading">
          <h2 style={{ color: secondaryColor }}>
            Product <span>Enquiry</span>
          </h2>
          <img src={headingLine} alt="" className="headline-image" />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row contact-second-section-row">
          <div className="col-lg-6 col-sm-12 contact-field-col">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
            >
              <div>
                <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="First Name"
                  name="first_name"
                  multiline
                  maxRows={4}
                  color="warning"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  required
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </div>
            </Box>
          </div>
          <div className="col-lg-6 col-sm-12 contact-field-col">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
            >
              <div>
                <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="Last Name"
                  name="last_name"
                  multiline
                  maxRows={4}
                  color="warning"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  required
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </div>
            </Box>
          </div>
        </div>
        <div className="row contact-second-section-row">
          <div className="col-lg-6 col-sm-12 contact-field-col">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
            >
              <div>
                <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="Email Id"
                  name="email"
                  multiline
                  maxRows={4}
                  color="warning"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </div>
            </Box>
          </div>
          <div className="col-lg-6 col-sm-12 contact-field-col">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
            >
              <div>
                <TextField
                  className="contact-box"
                  id="outlined-multiline-flexible"
                  label="Phone Number"
                  name="phone_number"
                  multiline
                  color="warning"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </div>
            </Box>
          </div>
        </div>

        <div className="row contact-second-section-row">
          <div className="col-lg-6 col-sm-12 contact-field-col contact-dropdown-col">
            <Box
              component="form"
              sx={{
                "& .MuiInputBase-root": { ml: 1, mr: 1, width: "100%" },
              }}
            >
              <FormControl
                fullWidth
                sx={{
                  " & .MuiInputBase-root .MuiOutlinedInput-input": {
                    color: secondaryColor,
                  },
                  " & .MuiFormLabel-root.MuiInputLabel-root": {
                    color: secondaryColor,
                  },
                  " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      color: secondaryColor,
                      borderColor: secondaryColor,
                    },
                  " & .MuiInputLabel-root": {
                    color: secondaryColor,
                  },
                  " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: secondaryColor,
                    },
                  // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                  //   {
                  //     borderColor: secondaryColor,
                  //   },
                  // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                  //   {
                  //     borderColor: secondaryColor,
                  //   },
                  " & .MuiInputBase-root.MuiOutlinedInput-root .MuiSvgIcon-root":
                    {
                      color: secondaryColor,
                    },
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  classes={{
                    root: "label-white", // Apply the 'label-white' class to the label root
                  }}
                  className="input-select-label"
                  style={{ color: secondaryColor }}
                  sx={{
                    color: secondaryColor,
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                  }}
                >
                  Product Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  // className="white-border" // Add this class to the Select component
                  classes={{
                    root: "select-root", // Apply the 'select-root' class to the Select root
                  }}
                  id="demo-simple-select"
                  value={selectedProductCategory}
                  label="Product Categories"
                  onChange={handleProductCategorySelect}
                  // sx={{
                  //   " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  //     {
                  //       color: secondaryColor,
                  //       borderColor: secondaryColor,
                  //     },
                  //   " & .MuiInputLabel-root": {
                  //     color: secondaryColor,
                  //   },
                  //   " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  //     {
                  //       borderColor: secondaryColor,
                  //     },
                  //   " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                  //     {
                  //       borderColor: secondaryColor,
                  //     },
                  // }}
                >
                  {productCategory.map((e: any) => (
                    <MenuItem key={e.product_category_id} value={e}>
                      {e.product_category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="col-lg-6 col-sm-12 contact-field-col contact-dropdown-col">
            <Box
              component="form"
              sx={{
                "& .MuiInputBase-root": { ml: 1, mr: 1, width: "100%" },
              }}
            >
              <FormControl
                fullWidth
                sx={{
                  " & .MuiInputBase-root .MuiOutlinedInput-input": {
                    color: secondaryColor,
                  },
                  " & .MuiFormLabel-root.MuiInputLabel-root": {
                    color: secondaryColor,
                  },
                  " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      color: secondaryColor,
                      borderColor: secondaryColor,
                    },
                  " & .MuiInputLabel-root": {
                    color: secondaryColor,
                  },

                  " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: secondaryColor,
                    },
                  // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                  //   {
                  //     borderColor: secondaryColor,
                  //   },
                  // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                  //   {
                  //     borderColor: secondaryColor,
                  //   },
                  " & .MuiInputBase-root.MuiOutlinedInput-root .MuiSvgIcon-root":
                    {
                      color: secondaryColor,
                    },
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  classes={{
                    root: "label-white", // Apply the 'label-white' class to the label root
                  }}
                  className="input-select-label"
                >
                  Product
                </InputLabel>
                <Select
                  classes={{
                    root: "select-root", // Apply the 'select-root' class to the Select root
                  }}
                  // className="dropdown-select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedProduct}
                  label="Products"
                  onChange={handleProductSelect}
                >
                  {products.map((e: any) => (
                    <MenuItem key={e.product_variety_id} value={e}>
                      {e.product_variety}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className="row contact-second-section-row">
          <div className="col-lg-12 col-sm-12 contact-field-col">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  className="contact-box custom-dropdown"
                  id="outlined-multiline-static"
                  label="Message"
                  name="message"
                  multiline
                  rows={6}
                  defaultValue=""
                  color="warning"
                  value={formData.message}
                  onChange={handleInputChange}
                  sx={{
                    " & .MuiInputBase-root .MuiOutlinedInput-input.MuiInputBase-inputMultiline":
                      {
                        color: secondaryColor,
                      },
                    " & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      },
                    " & .MuiInputLabel-root": {
                      color: secondaryColor,
                    },
                    " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: secondaryColor,
                      },
                    // " & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                    // " & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline:hover":
                    //   {
                    //     borderColor: secondaryColor,
                    //   },
                  }}
                />
              </div>
            </Box>
          </div>
        </div>
        <div className="row send-btn-row">
          <Button
            variant="contained"
            type="submit"
            sx={{
              bgcolor: "#FF6B00",
              "&:hover": {
                backgroundColor: "#FF6B00",
              },
            }}
          >
            <span>SUBMIT MESSAGE</span>
          </Button>
        </div>
      </form>
    </div>
  );
}
export default DealerFirstSection;
function useStyles() {
  throw new Error("Function not implemented.");
}
