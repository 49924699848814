import React from "react";
import Navbar from "../Navbar/Navbar";
import FirstSection from "./Product-Categories";
import Footer from "../Footer";
import ProductSection from "./Products";

function Products() {
  return (
    <>
      <Navbar />
      <ProductSection />
      <Footer />
    </>
  );
}

export default Products;
