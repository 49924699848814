import "./index.scss";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import DealerMeet from "../../../assets/images/Acceron/Gallery/DealerMeet.png";
import MechanicAward from "../../../assets/images/Acceron/GalleryMechanicAward.png";
import StallCampaign from "../../../assets/images/Acceron/Gallery/StallCampaign.png";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CancelIcon from "@mui/icons-material/Cancel";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import { get } from "../../../../utils/api";
import moment from "moment";
import Breadcrumb from "../../BreadCrumb";

function GalleryDetails() {
  const { primary_id } = useParams();

  console.log(primary_id);

  useEffect(() => {
    getGalleryDetails();
  }, []);

  const [data, setData] = useState<any>(null);
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const getGalleryDetails = async () => {
    let params = {
      primary_id: primary_id,
    };

    try {
      const response = await get(`galleries/details`, params);
      setData(response.data);
      if (response) {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [headingLine, setHeadingLine] = useState(HeadingLine);

  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  useEffect(() => {
    if (textColor === "light") {
      setHeadingLine(HeadingLine);
    } else {
      setHeadingLine(HeadingLineWhite);
    }
  }, [textColor]);

  const handleOpenModal = (index: any) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  const handlePrevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(data.images.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  const handleNextSlide = () => {
    slideNumber === data.images.length - 1
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <div
      className="row gallery-details-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <div className="col-xs-12 gallery-details-main">
        <div className="">
          <h2 style={{ color: secondaryColor, paddingRight: "20px" }}>
            {data && data.album_name}
          </h2>
          <p style={{ color: secondaryColor }}>
            {data && moment(data.creation_date).format("DD-MMM-YYYY")}{" "}
          </p>
        </div>
        {openModal && (
          <div className="gallery-details-modal">
            <ArrowCircleLeftIcon
              className="btn-prev"
              sx={{ width: "40px", height: "40px", color: secondaryColor }}
              onClick={handlePrevSlide}
            />
            <ArrowCircleRightIcon
              className="btn-next"
              sx={{ width: "40px", height: "40px", color: secondaryColor }}
              onClick={handleNextSlide}
            />
            <CancelIcon
              onClick={() => setOpenModal(false)}
              sx={{ width: "40px", height: "40px", color: secondaryColor }}
              className="btn-cross"
            />
            <div className="gallery-details-fullscreen">
              <img src={data.images[slideNumber].s3Url} alt="" />
            </div>
          </div>
        )}

        <div className="gallery-details-images">
          {data &&
            data.images !== null &&
            data.images !== undefined &&
            data.images.map((item: any, index: Number) => {
              return (
                <div
                  className="col-xs-12 col-md-2 col-lg-2 gallery-details-image-main"
                  key={item.image_id}
                  onClick={() => handleOpenModal(index)}
                >
                  <Card
                    sx={{
                      maxWidth: "268.77px",
                      maxHeight: "235px",
                      width: "100%",
                    }}
                  >
                    <CardMedia
                      sx={{
                        paddingTop: "87.7%",
                      }}
                      image={item.s3Url}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                  </Card>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default GalleryDetails;
