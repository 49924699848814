import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get } from "../../../../utils/api";
import "./ProductsDetailsTab.scss";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import ArticleIcon from "@mui/icons-material/Article";
import { Button, IconButton } from "@mui/material";

function ProductsDetailsTabSection(props: any) {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [highlightedMain, setHighlightedMain] = useState<boolean>(false);

  console.log("props", props.productData);

  const { product_id } = useParams();

  const handleHighlight = (index: number) => {
    setHighlightedIndex(index);
    setHighlightedMain(false);
  };

  const handleMainHighlight = () => {
    setHighlightedMain(true);
    setHighlightedIndex(null);
  };

  const handleOpenDocument = () => {
    window.open(props.productData.document_url);
  };

  const handleOpenHowToDocument = () => {
    window.open(props.productData.how_to_document_url);
  };

  return (
    <>
      <div className="row product-tetails-tab-row">
        <h1 style={{ color: secondaryColor }}>
          Product identifier : ACN -{" "}
          {props.productData && props.productData.product}
        </h1>
        {/* <h2>Product code ACCERON oil : ACN - 2000</h2> */}
        {/* <p>
          Relevant identified uses of the substance or mixture and uses advised
          against : This coolant should not be used for any other purpose than
          the intended use as Radiator Concentrated Coolant without expert
          advice.
        </p> */}
        {props.productVarieties && props.productVarieties.length > 0 && (
          <h3 style={{ color: secondaryColor }}>
            QUANTITIES :
            {props.productVarieties &&
              props.productVarieties.map((e: any, index: number) => (
                <>
                  {" "}
                  <span
                    key={index}
                    style={{
                      color: secondaryColor,
                      borderColor: secondaryColor,
                      marginLeft: 6,
                      marginRight: 6,
                      cursor: "pointer",
                      border:
                        highlightedIndex === index
                          ? `3px solid ${secondaryColor}`
                          : `1px solid ${secondaryColor}`,
                    }}
                    onClick={() => {
                      props.handleImage(e);
                      handleHighlight(index);
                    }}
                  >
                    {e.quantity}
                  </span>
                </>
              ))}
            {/* <span
            style={{
              color: secondaryColor,
              borderColor: secondaryColor,
              marginLeft: 6,
              marginRight: 6,
              cursor: "pointer",
              border: highlightedMain ? "3px solid #000" : "1px solid #000",
            }}
            onClick={() => {
              props.handleMainImage();
              handleMainHighlight();
            }}
          >
            MAIN
          </span> */}
          </h3>
        )}
        {props.productData &&
          props.productData.brochure_url !== null &&
          props.productData.brochure_url !== undefined && (
            <div style={{ padding: "1rem 0 0 0.750rem" }}>
              <Link
                to={props.productData.brochure_url}
                style={{ textDecoration: "none", color: "#FF6600" }}
              >
                {/* <span>DOWNLOAD BROCHURE</span> */}

                {props.productData.brochure_url &&
                  (() => {
                    const trimmedUrl = props.productData.brochure_url.replace(
                      /^https?:\/\/[^\/]+\/[^\/]+\//,
                      ""
                    );

                    const extension = trimmedUrl.split(".").pop();

                    const combinedName = `Download PDS`;
                    const maxLength = 20 - extension.length - 1;
                    return `${combinedName}`;
                  })()}
              </Link>
            </div>
          )}
        {props.productData &&
          props.productData.how_to_use_doc_url !== null &&
          props.productData.how_to_use_doc_url !== undefined && (
            <div style={{ padding: "1rem 0 0 0.750rem" }}>
              <Link
                to={props.productData.how_to_use_doc_url}
                style={{ textDecoration: "none", color: "#FF6600" }}
              >
                {/* <span>DOWNLOAD HOW TO USE</span> */}

                {props.productData.how_to_use_doc_url &&
                  (() => {
                    const trimmedUrl =
                      props.productData.how_to_use_doc_url.replace(
                        /^https?:\/\/[^\/]+\/[^\/]+\//,
                        ""
                      );

                    const extension = trimmedUrl.split(".").pop();

                    const combinedName = `Download MSDS`;
                    const maxLength = 20 - extension.length - 1;
                    return `${combinedName}`;
                  })()}
              </Link>
            </div>
          )}
      </div>
    </>
  );
}

export default ProductsDetailsTabSection;
