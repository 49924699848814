import Navbar from "../Navbar/Navbar";
import { darkThemeColors, lightThemeColors } from "../../../utils/colors";
import { getContrastColor } from "../../../utils/ColorUtils";
import { useTheme } from "../../../utils/ThemeContext";
import Footer from "../Footer";

export default function Terms() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  return (
    <>
      <Navbar />
      <div
        className="mx-auto px-4 sm:px-6 md:!px-8 lg:!px-28 py-8 sm:py-16 md:!py-12 lg:!py-12 md:!pt-32 lg:!pt-32"
        style={{ backgroundColor: primaryColor, color: secondaryColor }}
      >
        <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">
          TERMS AND CONDITIONS
        </h1>
        <p
          className="mb-4 text-xs sm:text-sm text-center"
          style={{ color: secondaryColor }}
        >
          Last updated January 24, 2025
        </p>

        <div className="prose prose-sm sm:prose max-w-none">
          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            1. AGREEMENT TO OUR LEGAL TERMS
          </h2>
          <p className="text-sm sm:text-base">
            We are Acceron Industries India Private Limited ("Company," "we,"
            "us," "our"), a company registered in India at 1-3-183/40/22/6B PLOT
            NO 41, P&T COLONY, GANDHI NAGAR, HYDERABAD, TELANGANA, HYDERABAD,
            TELANGANA 500080.
          </p>
          <p className="text-sm sm:text-base">
            We operate the website https://www.acceron.co (the "Site"), as well
            as any other related products and services that refer or link to
            these legal terms (the "Legal Terms") (collectively, the
            "Services").
          </p>
          <p className="text-sm sm:text-base">
            Acceron Industries is a trusted provider of a wide range of Engine
            Oils, Gear Oils, greases, and other fluids and lubes. Blended with
            our advanced additive technologies, every product is designed to
            enhance performance, reliability, and longevity for your use.
          </p>
          <p className="text-sm sm:text-base">
            You can contact us by phone at 9301789217, email at info@acceron.co,
            or by mail to 1-3-183/40/22/6B PLOT NO 41, P&T COLONY, GANDHI NAGAR,
            HYDERABAD, TELANGANA, HYDERABAD, TELANGANA 500080, India.
          </p>
          <p className="text-sm sm:text-base">
            These Legal Terms constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity ("you"),
            and Acceron Industries India Private Limited, concerning your access
            to and use of the Services. You agree that by accessing the
            Services, you have read, understood, and agreed to be bound by all
            of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
            TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND
            YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            2. INTELLECTUAL PROPERTY RIGHTS
          </h2>
          <p className="text-sm sm:text-base">
            Our intellectual property We are the owner or the licensee of all
            intellectual property rights in our Services, including all source
            code, databases, functionality, software, website designs, audio,
            video, text, photographs, and graphics in the Services
            (collectively, the "Content"), as well as the trademarks, service
            marks, and logos contained therein (the "Marks").
          </p>
          <p className="text-sm sm:text-base">
            Our Content and Marks are protected by copyright and trademark laws
            (and various other intellectual property rights and unfair
            competition laws) and treaties around the world.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            3. USER REPRESENTATIONS
          </h2>
          <p className="text-sm sm:text-base">
            By using the Services, you represent and warrant that: (1) you have
            the legal capacity and you agree to comply with these Legal Terms;
            (2) you are not a minor in the jurisdiction in which you reside; (3)
            you will not access the Services through automated or non-human
            means, whether through a bot, script, or otherwise; (4) you will not
            use the Services for any illegal or unauthorized purpose; and (5)
            your use of the Services will not violate any applicable law or
            regulation.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            4. PROHIBITED ACTIVITIES
          </h2>
          <p className="text-sm sm:text-base">
            You may not access or use the Services for any purpose other than
            that for which we make the Services available. The Services may not
            be used in connection with any commercial endeavors except those
            that are specifically endorsed or approved by us.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            5. USER GENERATED CONTRIBUTIONS
          </h2>
          <p className="text-sm sm:text-base">
            The Services may invite you to chat, contribute to, or participate
            in blogs, message boards, online forums, and other functionality,
            and may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Services, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions, or personal information or other material
            (collectively, "Contributions"). Contributions may be viewable by
            other users of the Services and through third-party websites.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            6. CONTRIBUTION LICENSE
          </h2>
          <p className="text-sm sm:text-base">
            By posting your Contributions to any part of the Services, you
            automatically grant, and you represent and warrant that you have the
            right to grant, to us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free, fully-paid,
            worldwide right, and license to host, use, copy, reproduce,
            disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial, advertising, or otherwise, and to
            prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            7. THIRD-PARTY WEBSITES AND CONTENT
          </h2>
          <p className="text-sm sm:text-base">
            The Services may contain (or you may be sent via the Site) links to
            other websites ("Third-Party Websites") as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties ("Third-Party
            Content"). Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Services or any
            Third-Party Content posted on, available through, or installed from
            the Services, including the content, accuracy, offensiveness,
            opinions, reliability, privacy practices, or other policies of or
            contained in the Third-Party Websites or the Third-Party Content.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            8. SERVICES MANAGEMENT
          </h2>
          <p className="text-sm sm:text-base">
            We reserve the right, but not the obligation, to: (1) monitor the
            Services for violations of these Legal Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Legal Terms, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems; and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            9. PRIVACY POLICY
          </h2>
          <p className="text-sm sm:text-base">
            We care about data privacy and security. Please review our Privacy
            Policy:{" "}
            <a
              href="https://www.acceron.co/privacy_policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              https://www.acceron.co/privacy_policy
            </a>
            . By using the Services, you agree to be bound by our Privacy
            Policy, which is incorporated into these Legal Terms. Please be
            advised the Services are hosted in India. If you access the Services
            from any other region of the world with laws or other requirements
            governing personal data collection, use, or disclosure that differ
            from applicable laws in India, then through your continued use of
            the Services, you are transferring your data to India, and you
            expressly consent to have your data transferred to and processed in
            India.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            10. COPYRIGHT INFRINGEMENTS
          </h2>
          <p className="text-sm sm:text-base">
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Services
            infringes upon any copyright you own or control, please immediately
            notify us using the contact information provided below (a
            "Notification"). A copy of your Notification will be sent to the
            person who posted or stored the material addressed in the
            Notification. Please be advised that pursuant to applicable law you
            may be held liable for damages if you make material
            misrepresentations in a Notification. Thus, if you are not sure that
            material located on or linked to by the Services infringes your
            copyright, you should consider first contacting an attorney.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            11. TERM AND TERMINATION
          </h2>
          <p className="text-sm sm:text-base">
            These Legal Terms shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
            TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE ANY CONTENT OR
            INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
            SOLE DISCRETION.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            12. MODIFICATIONS AND INTERRUPTIONS
          </h2>
          <p className="text-sm sm:text-base">
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services. We will not be liable to you or any
            third party for any modification, price change, suspension, or
            discontinuance of the Services.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            13. GOVERNING LAW
          </h2>
          <p className="text-sm sm:text-base">
            These Legal Terms shall be governed by and defined following the
            laws of India. Acceron Industries India Private Limited and yourself
            irrevocably consent that the courts of India shall have exclusive
            jurisdiction to resolve any dispute which may arise in connection
            with these Legal Terms.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            14. DISPUTE RESOLUTION
          </h2>
          <p className="text-sm sm:text-base">
            You agree to irrevocably submit all disputes related to these Legal
            Terms or the legal relationship established by these Legal Terms to
            the jurisdiction of the India courts. Acceron Industries India
            Private Limited shall also maintain the right to bring proceedings
            as to the substance of the matter in the courts of the country where
            you reside or, if these Legal Terms are entered into in the course
            of your trade or profession, the state of your principal place of
            business.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            15. CORRECTIONS
          </h2>
          <p className="text-sm sm:text-base">
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            16. DISCLAIMER
          </h2>
          <p className="text-sm sm:text-base">
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
            THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
            EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
            THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
            ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
            WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
            MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
            INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
            YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS
            TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
            INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
            (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
            (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
            CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            17. LIMITATIONS OF LIABILITY
          </h2>
          <p className="text-sm sm:text-base">
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
            TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO
            US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
            ARISING OR $0.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            18. INDEMNIFICATION
          </h2>
          <p className="text-sm sm:text-base">
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys' fees
            and expenses, made by any third party due to or arising out of: (1)
            your Contributions; (2) use of the Services; (3) breach of these
            Legal Terms; (4) any breach of your representations and warranties
            set forth in these Legal Terms; (5) your violation of the rights of
            a third party, including but not limited to intellectual property
            rights; or (6) any overt harmful act toward any other user of the
            Services with whom you connected via the Services. Notwithstanding
            the foregoing, we reserve the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are
            required to indemnify us, and you agree to cooperate, at your
            expense, with our defense of such claims. We will use reasonable
            efforts to notify you of any such claim, action, or proceeding which
            is subject to this indemnification upon becoming aware of it.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            19. USER DATA
          </h2>
          <p className="text-sm sm:text-base">
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </h2>
          <p className="text-sm sm:text-base">
            Visiting the Services, sending us emails, and completing online
            forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SERVICES. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            21. MISCELLANEOUS
          </h2>
          <p className="text-sm sm:text-base">
            These Legal Terms and any policies or operating rules posted by us
            on the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These
            Legal Terms operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Legal
            Terms is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Legal Terms and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that
            these Legal Terms will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Legal Terms and the lack
            of signing by the parties hereto to execute these Legal Terms.
          </p>

          <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-4">
            22. CONTACT US
          </h2>
          <p className="text-sm sm:text-base mb-2">
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at:
          </p>
          <address className="not-italic text-sm sm:text-base">
            <strong>Acceron Industries India Private Limited</strong>
            <br />
            1-3-183/40/22/6B PLOT NO 41, P&T COLONY, GANDHI NAGAR,
            <br />
            HYDERABAD, TELANGANA
            <br />
            HYDERABAD, TELANGANA 500080
            <br />
            India
            <br />
            <strong>Phone:</strong> 9301789217
            <br />
            <strong>Email:</strong> info@acceron.co
          </address>
        </div>
      </div>
      <Footer />
    </>
  );
}
