import Navbar from "../Navbar/Navbar";
import { darkThemeColors, lightThemeColors } from "../../../utils/colors";
import { getContrastColor } from "../../../utils/ColorUtils";
import { useTheme } from "../../../utils/ThemeContext";
import Footer from "../Footer";

export default function CookiePolicy() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  return (
    <>
      <Navbar />
      <div
        className="cookie-policy mx-auto px-4 sm:px-6 md:!px-8 lg:!px-28 py-8 sm:py-16 md:!py-12 lg:!py-12 md:!pt-32 lg:!pt-32 font-sans"
        style={{ backgroundColor: primaryColor, color: secondaryColor }}
      >
        <div className="mb-8">
          <h1 className="text-2xl sm:text-3xl font-bold mb-2 text-center">
            COOKIE POLICY
          </h1>
          <p className="text-sm text-center" style={{ color: secondaryColor }}>
            Last updated January 22, 2025
          </p>
        </div>

        <div className="space-y-6">
          <p className="text-sm sm:text-base">
            This Cookie Policy explains how Acceron uses cookies and similar
            technologies to recognize you when you visit our website at{" "}
            <a
              href="https://www.acceron.co"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              https://www.acceron.co
            </a>{" "}
            ("Website"). It explains what these technologies are and why we use
            them, as well as your rights to control our use of them.
          </p>

          <p className="text-sm sm:text-base">
            In some cases, we may use cookies to collect personal information,
            or that becomes personal information if we combine it with other
            information.
          </p>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              What are cookies?
            </h2>
            <p className="text-sm sm:text-base">
              Cookies are small data files that are placed on your computer or
              mobile device when you visit a website. Cookies are widely used by
              website owners in order to make their websites work, or to work
              more efficiently, as well as to provide reporting information.
            </p>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Why do we use cookies?
            </h2>
            <p className="text-sm sm:text-base">
              We use first- and third-party cookies for several reasons. Some
              cookies are required for technical reasons in order for our
              Website to operate, and we refer to these as "essential" or
              "strictly necessary" cookies. Other cookies also enable us to
              track and target the interests of our users to enhance the
              experience on our Online Properties. Third parties serve cookies
              through our Website for advertising, analytics, and other
              purposes.
            </p>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              How can I control cookies?
            </h2>
            <p className="text-sm sm:text-base">
              You have the right to decide whether to accept or reject cookies.
              You can exercise your cookie rights by setting your preferences in
              the Cookie Consent Manager. The Cookie Consent Manager allows you
              to select which categories of cookies you accept or reject.
              Essential cookies cannot be rejected as they are strictly
              necessary to provide you with services.
            </p>
            <p className="text-sm sm:text-base">
              The Cookie Consent Manager can be found in the notification banner
              and on our Website. If you choose to reject cookies, you may still
              use our Website though your access to some functionality and areas
              of our Website may be restricted. You may also set or amend your
              web browser controls to accept or refuse cookies.
            </p>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Types of Cookies We Use
            </h2>
            <h3 className="text-lg sm:text-xl font-semibold mt-4 mb-2">
              Performance and functionality cookies:
            </h3>
            <p className="text-sm sm:text-base">
              These cookies are used to enhance the performance and
              functionality of our Website but are non-essential to their use.
              However, without these cookies, certain functionality (like
              videos) may become unavailable.
            </p>
            <h3 className="text-lg sm:text-xl font-semibold mt-4 mb-2">
              Analytics and customization cookies:
            </h3>
            <p className="text-sm sm:text-base">
              These cookies collect information that is used either in aggregate
              form to help us understand how our Website is being used or how
              effective our marketing campaigns are, or to help us customize our
              Website for you.
            </p>
            <h3 className="text-lg sm:text-xl font-semibold mt-4 mb-2">
              Advertising cookies:
            </h3>
            <p className="text-sm sm:text-base">
              These cookies are used to make advertising messages more relevant
              to you. They perform functions like preventing the same ad from
              continuously reappearing, ensuring that ads are properly displayed
              for advertisers, and in some cases selecting advertisements that
              are based on your interests.
            </p>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              How can I control cookies on my browser?
            </h2>
            <p className="text-sm sm:text-base">
              As the means by which you can refuse cookies through your web
              browser controls vary from browser to browser, you should visit
              your browser's help menu for more information. The following is
              information about how to manage cookies on the most popular
              browsers:
            </p>
            <ul className="list-disc pl-5 mt-2 space-y-1">
              <li>
                <a
                  href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                  className="text-blue-600 hover:underline"
                >
                  Chrome
                </a>
              </li>
              <li>
                <a
                  href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  className="text-blue-600 hover:underline"
                >
                  Internet Explorer
                </a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop"
                  className="text-blue-600 hover:underline"
                >
                  Firefox
                </a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                  className="text-blue-600 hover:underline"
                >
                  Safari
                </a>
              </li>
              <li>
                <a
                  href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                  className="text-blue-600 hover:underline"
                >
                  Edge
                </a>
              </li>
              <li>
                <a
                  href="https://help.opera.com/en/latest/web-preferences/"
                  className="text-blue-600 hover:underline"
                >
                  Opera
                </a>
              </li>
            </ul>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              What about other tracking technologies, like web beacons?
            </h2>
            <p className="text-sm sm:text-base">
              Cookies are not the only way to recognize or track visitors to a
              website. We may use other, similar technologies from time to time,
              like web beacons (sometimes called "tracking pixels" or "clear
              gifs"). These are tiny graphics files that contain a unique
              identifier that enable us to recognize when someone has visited
              our Website or opened an email including them. This allows us, for
              example, to monitor the traffic patterns of users from one page
              within a website to another, to deliver or communicate with
              cookies, to understand whether you have come to the website from
              an online advertisement displayed on a third-party website, to
              improve site performance, and to measure the success of email
              marketing campaigns.
            </p>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Do you use Flash cookies or Local Shared Objects?
            </h2>
            <p className="text-sm sm:text-base">
              Websites may also use so-called "Flash Cookies" (also known as
              Local Shared Objects or "LSOs") to, among other things, collect
              and store information about your use of our services, fraud
              prevention, and for other site operations.
            </p>
            <p className="text-sm sm:text-base">
              If you do not want Flash Cookies stored on your computer, you can
              adjust the settings of your Flash player to block Flash Cookies
              storage. Flash Cookies are not managed by the same browser
              settings as are used for browser cookies.
            </p>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Do you serve targeted advertising?
            </h2>
            <p className="text-sm sm:text-base">
              Third parties may serve cookies on your computer or mobile device
              to serve advertising through our Website. These companies may use
              information about your visits to this and other websites in order
              to provide relevant advertisements about goods and services that
              you may be interested in. They may also employ technology that is
              used to measure the effectiveness of advertisements. They can
              accomplish this by using cookies or web beacons to collect
              information about your visits to this and other sites in order to
              provide relevant advertisements about goods and services of
              potential interest to you.
            </p>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              How often will you update this Cookie Policy?
            </h2>
            <p className="text-sm sm:text-base">
              We may update this Cookie Policy from time to time in order to
              reflect, for example, changes to the cookies we use or for other
              operational, legal, or regulatory reasons. Please therefore
              revisit this Cookie Policy regularly to stay informed about our
              use of cookies and related technologies.
            </p>
            <p className="text-sm sm:text-base">
              The date at the top of this Cookie Policy indicates when it was
              last updated.
            </p>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Where can I get further information?
            </h2>
            <p className="text-sm sm:text-base">
              If you have any questions about our use of cookies or other
              technologies, please contact us at:
            </p>
            <p className="mt-2 text-sm sm:text-base">
              Acceron Industries Private Limited
              <br />
              Address : 1-3-183/40/22/6B PLOT NO 41, P&T COLONY, GANDHI NAGAR,
              HYDERABAD, TELANGANA, 500080.
              <br />
              info@acceron.co
              <br />
              8305096448
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}
