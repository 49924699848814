import * as React from "react";
import "./Navbar.scss";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import HamburgerIcon from "../../assets/images/Malayali/Navbar/hamburger.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import CustomizedMenus from "../Dropdown/Dropdown";
import Dropdown from "../Dropdown/Dropdown";
import DropdownIcon from "../Dropdown/DropdownIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext, useEffect, useState } from "react";
import AppsDropdown from "../AppsDropdown";
import AcceronLogo from "../../assets/images/Acceron/Header/acceron-logo.svg";
import AcceronMobileLogo from "../../assets/images/Acceron/Header/acceron-mobile-logo.svg";
import MenuIcon from "../../assets/images/Acceron/Header/menu_orange_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import EmailIcon from "../../assets/images/Acceron/Footer/email-icon.png";
import CallIcon from "../../assets/images/Acceron/Footer/call-icon.png";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import { useTheme } from "../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../utils/colors";
import { getContrastColor } from "../../../utils/ColorUtils";
import AcceronLightLogo from "../../assets/images/Acceron/Header/light_mode_logo.svg";
import AcceronDarkLogo from "../../assets/images/Acceron/Header/dark_mode_logo.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const profileData = [
  {
    key: "Home",
    value: "Home",
    route: "/",
  },
  {
    key: "About Us",
    value: "About Us",
    route: "/about_us",
  },
  {
    key: "Products",
    value: "Products",
    route: "/product-category",
  },
  {
    key: "Careers",
    value: "Careers",
    route: "/careers",
  },
  // {
  //   key: "Product Range",
  //   value: "Product Range",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Engine Oils",
  //   value: "Engine Oils",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Greases",
  //   value: "Greases",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Gear Oils",
  //   value: "Gear Oils",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Coolants",
  //   value: "Coolants",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Contact Us",
  //   value: "Contact Us",
  //   // route: "/find_dealer",
  // },
  {
    key: "info@acceron.co",
    value: "info@acceron.co",
    image: EmailIcon,
    // route: "/find_dealer",
  },
  {
    key: "8305096448",
    value: "8305096448",
    image: CallIcon,
    // route: "/find_dealer",
  },
];

const handleFieldClick = (e: any) => {
  console.log(e.target.value);
};

export default function Navbar(props: any) {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  const navigate = useNavigate();
  useEffect(() => {
    // fetchProfile();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleAbout = () => {
    navigate("/about_us");
  };

  const handleProducts = () => {
    navigate("/product-category");
  };

  const handleCareers = () => {
    navigate("/careers");
  };

  const handleDealer = () => {
    navigate("/product_enquiry");
  };

  const handleContact = () => {
    navigate("/contact_us");
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const handleScreen = () => {
    // document.querySelector("body").style.overflow = "auto";
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          {/* <Badge badgeContent={17} color="error"> */}
          <NotificationsIcon />
          {/* </Badge> */}
        </IconButton>
        <p className="para-margin">Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <p className="para-margin">Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className="navbar-header"
        style={{ backgroundColor: primaryColor, color: secondaryColor }}
      >
        <Toolbar className="navbar-height">
          <div className="d-flex d-md-none w-100 justify-content-between align-items-center">
            <Button component={Link} to="/home">
              {theme === "light" ? (
                <img src={AcceronLightLogo} alt="" />
              ) : (
                <img src={AcceronDarkLogo} alt="" />
              )}
            </Button>
            <ThemeSwitcher />
            <Dropdown
              // name={state?.user?.first_name}
              // name={props.user.first_name}
              menuData={profileData}
              imagePath={MenuIcon}
              color="transparent"
              lineColor={secondaryColor}
              className="pro-btn-main"
              buttonClass="pro-nav-btn pro-acc-btn navbar-mobile-btn"
              menuClass="menu-navbar"
              // endIcon={<DropdownIcon />}
              // endIcon={<DropdownIcon iconColor="white" />}
              spanclass={"span-spacing"}
              customIcon={<LogoutIcon />}
              customText="Logout"
              // customFunction={handleLogout}
              // profileFunction={handleProfile}
              handleFieldClick={handleFieldClick}
            />
          </div>

          <div className="d-md-flex d-none w-100 justify-content-between align-items-center">
            <Button component={Link} to="/home">
              {theme === "light" ? (
                <img src={AcceronLightLogo} alt="" />
              ) : (
                <img src={AcceronDarkLogo} alt="" />
              )}
            </Button>
            <div className="d-flex justify-content-center align-items-center navbar-btn-container">
              <Button className="navbar-btns" onClick={handleHome}>
                <h4 style={{ color: secondaryColor }}>Home</h4>
              </Button>
              <Button className="navbar-btns" onClick={handleAbout}>
                <h4 style={{ color: secondaryColor }}>About Us</h4>
              </Button>
              <Button className="navbar-btns" onClick={handleProducts}>
                <h4 style={{ color: secondaryColor }}>Products</h4>
              </Button>
              <Button className="navbar-btns" onClick={handleCareers}>
                <h4 style={{ color: secondaryColor }}>Careers</h4>
              </Button>
              <Button
                className="dealer-btn"
                variant="outlined"
                onClick={handleDealer}
                style={{ borderColor: secondaryColor }}
              >
                <span style={{ color: secondaryColor }}>PRODUCT ENQUIRY</span>
              </Button>
              <Button
                className="contact-btn"
                variant="contained"
                onClick={handleContact}
              >
                <span>Contact Us</span>
              </Button>
              <ThemeSwitcher />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
