import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";

import Footer from "../Footer";
import NewsPageSection from "./First-Section-News";

function NewsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <NewsPageSection />
      <Footer />
    </>
  );
}

export default NewsPage;
