import React from "react";
import DividerLine from "../../../assets/images/Acceron/Home/Second-Section/divider-line.png";
import DividerLineBlack from "../../../assets/images/Acceron/Home/Second-Section/divider-line-black.png";
import EngineOilImg from "../../../assets/images/Acceron/Home/Second-Section/engine-oils.png";
import GearOilImg from "../../../assets/images/Acceron/Home/Second-Section/gear-oils.png";
import GreasesImg from "../../../assets/images/Acceron/Home/Second-Section/greases.png";
import FluidsImg from "../../../assets/images/Acceron/Home/Second-Section/fluids-and-lubes.png";
import EngineOilMobileImg from "../../../assets/images/Acceron/Home/Second-Section/engine-oils-mobile.png";
import GearOilMobileImg from "../../../assets/images/Acceron/Home/Second-Section/gear-oils-mobile.png";
import GreasesMobileImg from "../../../assets/images/Acceron/Home/Second-Section/greases-mobile.png";
import FluidsMobileImg from "../../../assets/images/Acceron/Home/Second-Section/fluids-and-lubes-mobile.png";
import Button from "@mui/material/Button";
import "./index.scss";
import { Link } from "react-router-dom";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";

function SecondSection() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  return (
    <div
      className="row second-section-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <div className="orange-container"></div>
      <div className="col-xs-12 second-section-main">
        <div className="second-section-text">
          <h4 style={{ color: secondaryColor }}>
            <span>Acceron's automotive lubricants:</span> Empowering engines,
            conquering friction, and maximising performance with advanced
            technology.
          </h4>
        </div>
        {/* <div className="divider-line">
          {textColor === "light" ? (
            <img
              src={DividerLineBlack}
              alt=""
              className="img-fluid headline-image"
            />
          ) : (
            <img
              src={DividerLine}
              alt=""
              className="img-fluid headline-image"
            />
          )}
        </div> */}
        <div className="d-md-flex d-none justify-content-center align-item-center second-section-features text-center">
          <img src={EngineOilImg} alt="" />
          <img src={GearOilImg} alt="" />
          <img src={GreasesImg} alt="" />
          <img src={FluidsImg} alt="" />
        </div>
        <div className="d-flex d-md-none justify-content-center align-item-center second-section-features text-center">
          <img src={EngineOilMobileImg} alt="" />
          <img src={GearOilMobileImg} alt="" />
          <img src={GreasesMobileImg} alt="" />
          <img src={FluidsMobileImg} alt="" />
        </div>
        <div className="second-section-desc-container">
          <div className="second-section-description">
            <p>
              Discover peak performance with Acceron Industries. Our advanced
              lubrication solutions for engines, gears, and more redefine
              reliability and efficiency, driving your success forward.
            </p>
          </div>
          <div>
            <Link to={`/product-category`} className="no-text-decoration">
              <Button variant="outlined" className="explore-product-btn">
                <span>EXPLORE THE PRODUCT RANGE</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
