import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import CareersFirstSection from "./First-Section";
import SEO from "../SEO";

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        title="Careers"
        description="We're seeking top talent to maintain our leadership in the field. Join us to develop your skills and personality to the fullest."
        keywords="talent, join, develop, leadership, skills, personality"
        image="../../assets/images/Acceron/careers/careers_img.png"
        url="https://www.acceron.co/careers"
      />
      <Navbar />
      <CareersFirstSection />
      <Footer />
    </>
  );
}

export default Careers;
