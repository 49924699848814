import Card from "@mui/material/Card";
import "./index.scss";
import TWO_WHEELER_ENGINE_OIL from "../../../assets/images/Acceron/Products/TWO WHEELER ENGINE OIL.png";
import FRONT_FORK_SHOCK_OIL from "../../../assets/images/Acceron/Products/FRONT FORK SHOCK.png";
import COOLANTS_OIL from "../../../assets/images/Acceron/Products/COOLANTS.png";
import CAR_SEGMENT_OIL from "../../../assets/images/Acceron/Products/CAR SEGMENT OIL.png";
import GEAR_OIL from "../../../assets/images/Acceron/Products/GEAR OIL.png";
import GREASES from "../../../assets/images/Acceron/Products/GREASES.png";
import HYDRAULIC_OIL from "../../../assets/images/Acceron/Products/HYDRAULIC OIL.png";
import DIESEL_ENGINE_OIL from "../../../assets/images/Acceron/Products/STATIONARY DIESEL ENGINE OIL.png";
import TRACTOR_ENGINE_OIL from "../../../assets/images/Acceron/Products/TRACTOR ENGINE OIL.png";
import TRANSMISSION_OIL from "../../../assets/images/Acceron/Products/TRANSMISSION OIL.png";
import WET_BRAKE_OIL from "../../../assets/images/Acceron/Products/WET BRAKE OIL UTTO.png";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import { useEffect, useState } from "react";
import { get } from "../../../../utils/api";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import { text } from "stream/consumers";
// const navigate = useNavigate();
// useEffect(() => {
//   // fetchProfile();
// }, []);

// const handleProductsDetailsPage = () => {
//   navigate("/product_details");
// };
function ProductVariety() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [data, setData] = useState<Array<any>>([]);
  const [totalCount, setTotalCount] = useState(0);

  const { product_category_id } = useParams();

  useEffect(() => {
    console.log(product_category_id);
    let filter = [
      {
        key: "product_category_id",
        value: product_category_id,
        type: "Number",
      },
    ];
    fetchData(filter, 1, 20);
  }, []);

  const fetchData = (filter: any, page_no: number = 1, limit: number = 10) => {
    get("products", {
      filter: filter,
      page: page_no,
      limit: limit,
    }).then((response) => {
      setData(
        response.data.data.filter(
          (e: any) => e.status === "active" || e.status === "Active"
        )
      );
      setTotalCount(response.data.totalCount);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="container-fluid product-section-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <div className="row product-header-row">
        <div className="d-flex justify-content-start align-items-center product-section-heading">
          <h2>Products</h2>
          {textColor === "light" ? (
            <img
              src={HeadingLine}
              alt=""
              className="img-fluid headline-image"
            />
          ) : (
            <img
              src={HeadingLineWhite}
              alt=""
              className="img-fluid headline-image"
            />
          )}
        </div>
      </div>
      {/* ################################################## */}

      <div className="row product_sub_line_row">
        <p
          style={{
            color: secondaryColor,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link
            to={`/product-category`}
            className="no-text-decoration md:w-auto lg:w-auto"
            style={{
              color: secondaryColor,
              marginRight: "0.75rem",
              width: "6rem",
            }}
          >
            {" "}
            Product Categories{" "}
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            className="svg-space"
            style={{ marginRight: "0.75rem" }}
          >
            <path
              d="M6.50695 12.379C6.32247 12.38 6.14185 12.3287 5.98811 12.2317C5.83438 12.1346 5.7145 11.9962 5.64376 11.834C5.57301 11.6719 5.55461 11.4933 5.5909 11.3212C5.62719 11.149 5.71652 10.9911 5.84751 10.8674L10.7701 6.19143L5.84751 1.51542C5.69535 1.34632 5.61583 1.1288 5.62486 0.906337C5.63389 0.683871 5.7308 0.472841 5.89621 0.315417C6.06162 0.157993 6.28336 0.0657706 6.51712 0.0571778C6.75087 0.0485849 6.97943 0.124255 7.15711 0.269067L12.7299 5.57268C12.9029 5.73829 13 5.96233 13 6.19585C13 6.42937 12.9029 6.65341 12.7299 6.81903L7.15711 12.1226C6.98411 12.2859 6.75061 12.378 6.50695 12.379Z"
              fill={secondaryColor}
            />
            <path
              d="M0.934198 12.379C0.749716 12.38 0.569094 12.3287 0.41536 12.2316C0.261626 12.1346 0.141745 11.9961 0.0710017 11.834C0.000258312 11.6718 -0.0181425 11.4933 0.0181452 11.3212C0.054433 11.149 0.143765 10.9911 0.274753 10.8674L5.19737 6.19141L0.274753 1.51539C0.0998568 1.34894 0.00160127 1.12319 0.00160127 0.887796C0.00160127 0.652403 0.0998568 0.42665 0.274753 0.260202C0.449648 0.0937539 0.686858 0.000244142 0.934198 0.000244141C1.18154 0.000244139 1.41875 0.0937539 1.59364 0.260202L7.16642 5.56381C7.33941 5.72943 7.43651 5.95346 7.43651 6.18699C7.43651 6.42051 7.33941 6.64455 7.16642 6.81016L1.59364 12.1138C1.50762 12.1973 1.40504 12.2637 1.29185 12.3093C1.17865 12.3548 1.05708 12.3785 0.934198 12.379Z"
              fill={secondaryColor}
            />
          </svg>
          {data[0] && data[0]["product_category"] && (
            <Link
              to={`/products/${product_category_id}`}
              className="no-text-decoration"
              style={{ color: secondaryColor }}
            >
              {" "}
              <span className="link" style={{ color: secondaryColor }}>
                {data[0]["product_category"]}
              </span>
            </Link>
          )}{" "}
        </p>
      </div>

      <div className="row product-category-row">
        {data.map((e, index) => (
          <div className="col-lg-3  products-image-col">
            <Link
              to={`/product_details/${e.product_id}`}
              className="no-text-decoration"
            >
              <Card
                // onClick={handleProductsDetailsPage}
                sx={{ borderRadius: 0 }}
              >
                <div
                  className="card product-image-card"
                  style={{
                    // borderColor: secondaryColor,
                    backgroundColor: textColor === "light" ? "#fff" : "#000",
                  }}
                >
                  <img
                    src={e.image_url}
                    className="card-img-top img-fluid product-item-image"
                    // style={{ borderColor: secondaryColor }}
                    alt="..."
                  />
                  <div className="card-body product-image-card-body">
                    <h5
                      className="card-title product-image-card-title"
                      style={{ color: secondaryColor }}
                    >
                      {e.product}
                    </h5>
                  </div>
                </div>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductVariety;
