import "./index.scss";
import Card from "@mui/material/Card";
import "./index.scss";
import TWO_WHEELER_ENGINE_OIL from "../../../assets/images/Acceron/Products/TWO WHEELER ENGINE OIL.png";
import FRONT_FORK_SHOCK_OIL from "../../../assets/images/Acceron/Products/FRONT FORK SHOCK.png";
import COOLANTS_OIL from "../../../assets/images/Acceron/Products/COOLANTS.png";
import CAR_SEGMENT_OIL from "../../../assets/images/Acceron/Products/CAR SEGMENT OIL.png";
import GEAR_OIL from "../../../assets/images/Acceron/Products/GEAR OIL.png";
import GREASES from "../../../assets/images/Acceron/Products/GREASES.png";
import HYDRAULIC_OIL from "../../../assets/images/Acceron/Products/HYDRAULIC OIL.png";
import DIESEL_ENGINE_OIL from "../../../assets/images/Acceron/Products/STATIONARY DIESEL ENGINE OIL.png";
import TRACTOR_ENGINE_OIL from "../../../assets/images/Acceron/Products/TRACTOR ENGINE OIL.png";
import TRANSMISSION_OIL from "../../../assets/images/Acceron/Products/TRANSMISSION OIL.png";
import WET_BRAKE_OIL from "../../../assets/images/Acceron/Products/WET BRAKE OIL UTTO.png";
import AcceronCoolant from "../../../assets/images/Acceron/Products/Acceron Coolant.png";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import { SetStateAction, useEffect, useState } from "react";
import FirstSection from "../../Home/FirstSection";
import ProductsDetailsTabSection from "./ProductsDetailsTab";
import { get } from "../../../../utils/api";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CardContent, Divider } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, paddingLeft: 1 }}>
          <Typography sx={{ color: secondaryColor }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProductDetails() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  const [value, setValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab: SetStateAction<string>) => {
    setActiveTab(tab);
  };

  const [data, setData] = useState<any>(null);
  const [productVarieties, setProductVarieties] = useState<any>(null);
  // const [productVarietyData, setProductVarietyData] = useState<any>([]);
  const [productVarietyImage, setProductVarietyImage] = useState<any>(null);

  const { product_id } = useParams();

  useEffect(() => {
    console.log(product_id);
    getProductDetails();
    getProductVarieties();
  }, []);

  const getProductDetails = () => {
    let params = {
      product_id: Number(product_id),
    };

    get("products/details", params)
      .then((response: any) => {
        let productData = response.data;
        console.log(productData);
        setData(productData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getProductVarieties = () => {
    // let params = {
    //   product_id: Number(product_id),
    // };

    const params = {
      page: 1,
      limit: 10,
      filter: [
        { key: "product_id", value: Number(product_id), type: "Number" },
        { key: "status_id", value: 1, type: "Number" },
      ],
      sortBy: {
        product_id: -1,
      },
    };

    get("product_variety", params)
      .then((response: any) => {
        let productData = response.data.data;
        setProductVarieties(productData);
        // setProductVarietyData(productData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleImage = (e: any) => {
    console.log(e.image_url);
    setProductVarietyImage(e.image_url);
  };

  const handleMainImage = () => {
    setProductVarietyImage("");
  };

  return (
    <div
      className="container-fluid product-section-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <div className="row product-header-row">
        <div className="d-flex justify-content-start align-items-center product-section-heading">
          <h2>Products</h2>
          {textColor === "light" ? (
            <img
              src={HeadingLine}
              alt=""
              className="img-fluid headline-image"
            />
          ) : (
            <img
              src={HeadingLineWhite}
              alt=""
              className="img-fluid headline-image"
            />
          )}
        </div>
      </div>
      <div className="row product_sub_line_row">
        <p>
          <Link
            to={`/product-category`}
            className="no-text-decoration"
            style={{ color: secondaryColor }}
          >
            {" "}
            Product Category{" "}
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            className="svg-space"
          >
            <path
              d="M6.50695 12.379C6.32247 12.38 6.14185 12.3287 5.98811 12.2317C5.83438 12.1346 5.7145 11.9962 5.64376 11.834C5.57301 11.6719 5.55461 11.4933 5.5909 11.3212C5.62719 11.149 5.71652 10.9911 5.84751 10.8674L10.7701 6.19143L5.84751 1.51542C5.69535 1.34632 5.61583 1.1288 5.62486 0.906337C5.63389 0.683871 5.7308 0.472841 5.89621 0.315417C6.06162 0.157993 6.28336 0.0657706 6.51712 0.0571778C6.75087 0.0485849 6.97943 0.124255 7.15711 0.269067L12.7299 5.57268C12.9029 5.73829 13 5.96233 13 6.19585C13 6.42937 12.9029 6.65341 12.7299 6.81903L7.15711 12.1226C6.98411 12.2859 6.75061 12.378 6.50695 12.379Z"
              fill={secondaryColor}
            />
            <path
              d="M0.934198 12.379C0.749716 12.38 0.569094 12.3287 0.41536 12.2316C0.261626 12.1346 0.141745 11.9961 0.0710017 11.834C0.000258312 11.6718 -0.0181425 11.4933 0.0181452 11.3212C0.054433 11.149 0.143765 10.9911 0.274753 10.8674L5.19737 6.19141L0.274753 1.51539C0.0998568 1.34894 0.00160127 1.12319 0.00160127 0.887796C0.00160127 0.652403 0.0998568 0.42665 0.274753 0.260202C0.449648 0.0937539 0.686858 0.000244142 0.934198 0.000244141C1.18154 0.000244139 1.41875 0.0937539 1.59364 0.260202L7.16642 5.56381C7.33941 5.72943 7.43651 5.95346 7.43651 6.18699C7.43651 6.42051 7.33941 6.64455 7.16642 6.81016L1.59364 12.1138C1.50762 12.1973 1.40504 12.2637 1.29185 12.3093C1.17865 12.3548 1.05708 12.3785 0.934198 12.379Z"
              fill={secondaryColor}
            />
          </svg>
          {""}{" "}
          {data && data.product_category && (
            // <>{data[0]["product_category"]}</>
            <Link
              to={`/products/${data.product_category_id}`}
              className="no-text-decoration"
              style={{ color: secondaryColor }}
            >
              {" "}
              {data.product_category}
            </Link>
          )}{" "}
          {""}
        </p>
      </div>

      {/* ################################################## */}
      <div className="row product-details-category-row">
        {productVarietyImage ? (
          <div className="col-lg-5 col-sm-12">
            <Card sx={{ height: "80%" }}>
              <div
                className="card product-details-image-card"
                style={{
                  backgroundColor: textColor === "light" ? "#fff" : "#201c1c",
                  borderColor: secondaryColor,
                  height: "100%",
                }}
              >
                <img
                  src={productVarietyImage}
                  className="w-100 card-img-top img-fluid Product-details-image"
                  alt="..."
                />
              </div>
            </Card>
          </div>
        ) : (
          <div className="col-lg-5 col-sm-12">
            <Card sx={{ height: "80%" }}>
              <div
                className="card product-details-image-card"
                style={{
                  backgroundColor: textColor === "light" ? "#fff" : "#201c1c",
                  borderColor: secondaryColor,
                  height: "100%",
                }}
              >
                <img
                  src={data && data.image_url}
                  className="w-100 card-img-top img-fluid Product-details-image"
                  alt="..."
                />
              </div>
            </Card>
          </div>
        )}

        <div className="col-lg-7 col-sm-12">
          <div className="product-item-name-row">
            <h1 style={{ color: secondaryColor }}>
              {/* Acceron <span>Coolant</span>
               */}
              {data && <p>{data.product}</p>}
            </h1>
          </div>
          <div className="item-tab-row">
            <div>
              <div className="tab-buttons">
                <button
                  className={`tab-button ${
                    activeTab === "tab1" ? "active" : ""
                  }`}
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => handleTabClick("tab1")}
                >
                  <span style={{ color: secondaryColor }}>Product Details</span>
                </button>
                {/* <button
                  className={`tab-button ${
                    activeTab === "tab2" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("tab2")}
                >
                  Dealers Details
                </button> */}
              </div>
              <Divider sx={{ bgcolor: secondaryColor }} />
              <div className="tab-content">
                {activeTab === "tab1" && (
                  <div>
                    <ProductsDetailsTabSection
                      productVarieties={productVarieties}
                      productData={data}
                      handleImage={handleImage}
                      handleMainImage={handleMainImage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {data && data.product_description !== "" && (
        <div className="row mt-5">
          <div className="col-xs-12">
            <Box
              sx={{
                borderBottom: 1,
                borderColor: secondaryColor,
              }}
            >
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{
                  " & .MuiTabs-indicator": {
                    backgroundColor: secondaryColor,
                  },
                  " & .MuiTab-root.MuiButtonBase-root": {
                    color: primaryColor,
                    bgcolor: secondaryColor,
                  },
                }}
                className="product-details-tab"
              >
                <Tab
                  label="Product Description"
                  {...a11yProps(0)}
                  sx={{
                    textTransform: "capitalize",
                  }}
                />
                {/* <Tab label="Item Two" {...a11yProps(1)} />
              <Tab label="Item Three" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              {data && data.product_description}
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={1}>
            Item Two
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductDetails;
