import "./ProductEnquiry.scss";
import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";

import Footer from "../Footer";
import DealerFirstSection from "./First-Section";
import SEO from "../SEO";

function FindDealer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        title="Product Enquiry"
        description="Product Enquiry"
        keywords="product, enquiry, information, contact"
        image="../../assets/images/Acceron/Products/GEAR OIL.png"
        url="https://www.acceron.co/product_enquiry"
      />
      <Navbar />
      <DealerFirstSection />
      <Footer />
    </>
  );
}

export default FindDealer;
