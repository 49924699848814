import React, { useEffect } from "react";
import AboutFirstSection from "./First-Section";
import Navbar from "../Navbar/Navbar";
import ThirdSection from "../Home/Third-Section";
import Footer from "../Footer";
import "./About.scss";
import AboutSecondSection from "./Second-Section";
import SEO from "../SEO";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title="About Us"
        description="Acceron Industries is a trusted provider of a wide range of Engine Oils, Gear Oils, greases, and other fluids and lubes."
        keywords="engine oil, gear oil, grease, technologies"
        image="../../assets/images/Acceron/Header/acceron-logo.svg"
        url="https://www.acceron.co/about_us"
      />
      <Navbar />
      <AboutFirstSection />
      <div className="position-relative">
        <div className="about-orange-container"></div>
        <ThirdSection />
      </div>
      <AboutSecondSection />
      <Footer />
    </>
  );
}

export default AboutUs;
