import { darkThemeColors, lightThemeColors } from "../../../utils/colors";
import { getContrastColor } from "../../../utils/ColorUtils";
import { useTheme } from "../../../utils/ThemeContext";
import Footer from "../Footer";
import Navbar from "../Navbar/Navbar";

export default function PrivacyPolicy() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  return (
    <>
      <Navbar />
      <div
        className="mx-auto px-4 sm:px-6 md:!px-8 lg:!px-28 py-8 sm:py-16 md:!py-12 lg:!py-12 md:!pt-32 lg:!pt-32 font-sans"
        style={{ backgroundColor: primaryColor, color: secondaryColor }}
      >
        <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">
          PRIVACY POLICY
        </h1>
        <p
          className="text-sm mb-8 text-center"
          style={{ color: secondaryColor }}
        >
          Last updated January 23, 2025
        </p>

        <section className="mb-8">
          <p className="mb-3 sm:mb-4">
            This Privacy Notice for Acceron Industries India Private Limited
            ("we," "us," or "our"), describes how and why we might access,
            collect, store, use, and/or share ("process") your personal
            information when you use our services ("Services"), including when
            you:
          </p>
          <ul className="list-disc pl-4 sm:pl-5 mb-3 sm:mb-4">
            <li>
              Visit our website at{" "}
              <a
                href="https://www.acceron.co"
                className="text-blue-600 hover:underline"
              >
                https://www.acceron.co
              </a>
              , or any website of ours that links to this Privacy Notice
            </li>
            <li>
              Use Acceron's automotive lubricants. Acceron Industries is a
              trusted provider of a wide range of Engine Oils, Gear Oils,
              greases, and other fluids and lubes. Blended with our advanced
              additive technologies, every product is designed to enhance
              performance, reliability, and longevity for your use.
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <p className="mb-3 sm:mb-4">
            <strong>Questions or concerns?</strong> Reading this Privacy Notice
            will help you understand your privacy rights and choices. If you do
            not agree with our policies and practices, please do not use our
            Services. If you still have any questions or concerns, please
            contact us at siddhant49kaushik@gmail.com.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            SUMMARY OF KEY POINTS
          </h2>
          <p className="mb-3 sm:mb-4">
            <em>
              This summary provides key points from our Privacy Notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </em>
          </p>
          <ul className="list-disc pl-4 sm:pl-5 mb-3 sm:mb-4">
            <li>
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with us and the
              Services, the choices you make, and the products and features you
              use.
            </li>
            <li>
              <strong>Do we process any sensitive personal information?</strong>{" "}
              We do not process sensitive personal information.
            </li>
            <li>
              <strong>Do we receive any information from third parties?</strong>{" "}
              We may receive information from public databases, marketing
              partners, social media platforms, and other outside sources.
            </li>
            <li>
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent.
            </li>
            <li>
              <strong>
                In what situations and with which types of parties do we share
                personal information?
              </strong>{" "}
              We may share information in specific situations and with specific
              categories of third parties.
            </li>
            <li>
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by submitting a data subject access
              request, or by contacting us. We will consider and act upon any
              request in accordance with applicable data protection laws.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            1. WHAT INFORMATION DO WE COLLECT?
          </h2>
          <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3">
            Personal information you disclose to us
          </h3>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> We collect personal information that
              you provide to us.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            We collect personal information that you voluntarily provide to us
            when you express an interest in obtaining information about us or
            our products and Services, when you participate in activities on the
            Services, or otherwise when you contact us.
          </p>
          <p className="mb-3 sm:mb-4">
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>
          <ul className="list-disc pl-4 sm:pl-5 mb-3 sm:mb-4">
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
            <li>mailing addresses</li>
          </ul>
          <p className="mb-3 sm:mb-4">
            <strong>Sensitive Information.</strong> We do not process sensitive
            information.
          </p>
          <p className="mb-3 sm:mb-4">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3">
            Information automatically collected
          </h3>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> Some information — such as your
              Internet Protocol (IP) address and/or browser and device
              characteristics — is collected automatically when you visit our
              Services.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p className="mb-3 sm:mb-4">
            Like many businesses, we also collect information through cookies
            and similar technologies. You can find out more about this in our
            Cookie Notice:{" "}
            <a
              href="https://www.acceron.co/cookie_policy"
              className="text-blue-600 hover:underline"
            >
              https://www.acceron.co/cookie_policy
            </a>
            .
          </p>
          <p className="mb-3 sm:mb-4">The information we collect includes:</p>
          <ul className="list-disc pl-4 sm:pl-5 mb-3 sm:mb-4">
            <li>
              <em>Log and Usage Data.</em> Log and usage data is
              service-related, diagnostic, usage, and performance information
              our servers automatically collect when you access or use our
              Services and which we record in log files. Depending on how you
              interact with us, this log data may include your IP address,
              device information, browser type, and settings and information
              about your activity in the Services (such as the date/time stamps
              associated with your usage, pages and files viewed, searches, and
              other actions you take such as which features you use), device
              event information (such as system activity, error reports
              (sometimes called "crash dumps"), and hardware settings).
            </li>
            <li>
              <em>Device Data.</em> We collect device data such as information
              about your computer, phone, tablet, or other device you use to
              access the Services. Depending on the device used, this device
              data may include information such as your IP address (or proxy
              server), device and application identification numbers, location,
              browser type, hardware model, Internet service provider and/or
              mobile carrier, operating system, and system configuration
              information.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </h2>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> We process your information to provide,
              improve, and administer our Services, communicate with you, for
              security and fraud prevention, and to comply with law. We may also
              process your information for other purposes with your consent.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            <strong>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </strong>
          </p>
          <ul className="list-disc pl-4 sm:pl-5 mb-3 sm:mb-4">
            <li>
              <strong>
                To deliver and facilitate delivery of services to the user.
              </strong>{" "}
              We may process your information to provide you with the requested
              service.
            </li>
            <li>
              <strong>
                To respond to user inquiries/offer support to users.
              </strong>{" "}
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>
            <li>
              <strong>To fulfill and manage your orders.</strong> We may process
              your information to fulfill and manage your orders, payments,
              returns, and exchanges made through the Services.
            </li>
            <li>
              <strong>To enable user-to-user communications.</strong> We may
              process your information if you choose to use any of our offerings
              that allow for communication with another user.
            </li>
            <li>
              <strong>
                To send you marketing and promotional communications.
              </strong>{" "}
              We may process the personal information you send to us for our
              marketing purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
            </li>
            <li>
              <strong>To deliver targeted advertising to you.</strong> We may
              process your information to develop and display personalized
              content and advertising tailored to your interests, location, and
              more.
            </li>
            <li>
              <strong>To post testimonials.</strong> We post testimonials on our
              Services that may contain personal information.
            </li>
            <li>
              <strong>
                To evaluate and improve our Services, products, marketing, and
                your experience.
              </strong>{" "}
              We may process your information when we believe it is necessary to
              identify usage trends, determine the effectiveness of our
              promotional campaigns, and to evaluate and improve our Services,
              products, marketing, and your experience.
            </li>
            <li>
              <strong>To identify usage trends.</strong> We may process
              information about how you use our Services to better understand
              how they are being used so we can improve them.
            </li>
            <li>
              <strong>
                To determine the effectiveness of our marketing and promotional
                campaigns.
              </strong>{" "}
              We may process your information to better understand how to
              provide marketing and promotional campaigns that are most relevant
              to you.
            </li>
            <li>
              <strong>To comply with our legal obligations.</strong> We may
              process your information to comply with our legal obligations,
              respond to legal requests, and exercise, establish, or defend our
              legal rights.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h2>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> We may share information in specific
              situations described in this section and/or with the following
              categories of third parties.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            <strong>
              Vendors, Consultants, and Other Third-Party Service Providers.
            </strong>{" "}
            We may share your data with third-party vendors, service providers,
            contractors, or agents ("third parties") who perform services for us
            or on our behalf and require access to such information to do that
            work.
          </p>
          <p className="mb-3 sm:mb-4">
            We also may need to share your personal information in the following
            situations:
          </p>
          <ul className="list-disc pl-4 sm:pl-5 mb-3 sm:mb-4">
            <li>
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </h2>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> We may use cookies and other tracking
              technologies to collect and store your information.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to gather information when you interact with our
            Services. Some online tracking technologies help us maintain the
            security of our Services, prevent crashes, fix bugs, save your
            preferences, and assist with basic site functions.
          </p>
          <p className="mb-3 sm:mb-4">
            We also permit third parties and service providers to use online
            tracking technologies on our Services for analytics and advertising,
            including to help manage and display advertisements, to tailor
            advertisements to your interests, or to send abandoned shopping cart
            reminders (depending on your communication preferences). The third
            parties and service providers use their technology to provide
            advertising about products and services tailored to your interests
            which may appear either on our Services or on other websites.
          </p>
          <p className="mb-3 sm:mb-4">
            Specific information about how we use such technologies and how you
            can refuse certain cookies is set out in our Cookie Notice:{" "}
            <a
              href="https://www.acceron.co/cookie_policy"
              className="text-blue-600 hover:underline"
            >
              https://www.acceron.co/cookie_policy
            </a>
            .
          </p>
          <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3">
            Google Analytics
          </h3>
          <p className="mb-3 sm:mb-4">
            We may share your information with Google Analytics to track and
            analyze the use of the Services. To opt out of being tracked by
            Google Analytics across the Services, visit{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              className="text-blue-600 hover:underline"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>
            . For more information on the privacy practices of Google, please
            visit the{" "}
            <a
              href="https://policies.google.com/privacy"
              className="text-blue-600 hover:underline"
            >
              Google Privacy & Terms page
            </a>
            .
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h2>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> We keep your information for as long as
              necessary to fulfill the purposes outlined in this Privacy Notice
              unless otherwise required by law.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this Privacy Notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements).
          </p>
          <p className="mb-3 sm:mb-4">
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            6. DO WE COLLECT INFORMATION FROM MINORS?
          </h2>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> We do not knowingly collect data from
              or market to children under 18 years of age.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            We do not knowingly collect, solicit data from, or market to
            children under 18 years of age, nor do we knowingly sell such
            personal information. By using the Services, you represent that you
            are at least 18 or that you are the parent or guardian of such a
            minor and consent to such minor dependent's use of the Services. If
            we learn that personal information from users less than 18 years of
            age has been collected, we will deactivate the account and take
            reasonable measures to promptly delete such data from our records.
            If you become aware of any data we may have collected from children
            under age 18, please contact us at siddhant49kaushik@gmail.com.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            7. WHAT ARE YOUR PRIVACY RIGHTS?
          </h2>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> You may review, change, or terminate
              your account at any time, depending on your country, province, or
              state of residence.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            <strong>
              <u>Withdrawing your consent:</u>
            </strong>{" "}
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time. You can withdraw your consent at any time by contacting
            us by using the contact details provided in the section "HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE?" below.
          </p>
          <p className="mb-3 sm:mb-4">
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p className="mb-3 sm:mb-4">
            <strong>
              <u>Cookies and similar technologies:</u>
            </strong>{" "}
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Services. For further information, please see our Cookie Notice:{" "}
            <a
              href="https://www.acceron.co/cookie_policy"
              className="text-blue-600 hover:underline"
            >
              https://www.acceron.co/cookie_policy
            </a>
            .
          </p>
          <p className="mb-3 sm:mb-4">
            If you have questions or comments about your privacy rights, you may
            email us at siddhant49kaushik@gmail.com.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            8. CONTROLS FOR DO-NOT-TRACK FEATURES
          </h2>
          <p className="mb-3 sm:mb-4">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage, no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this Privacy Notice.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            9. DO WE MAKE UPDATES TO THIS NOTICE?
          </h2>
          <p className="mb-3 sm:mb-4">
            <em>
              <strong>In Short:</strong> Yes, we will update this notice as
              necessary to stay compliant with relevant laws.
            </em>
          </p>
          <p className="mb-3 sm:mb-4">
            We may update this Privacy Notice from time to time. The updated
            version will be indicated by an updated "Revised" date at the top of
            this Privacy Notice. If we make material changes to this Privacy
            Notice, we may notify you either by prominently posting a notice of
            such changes or by directly sending you a notification. We encourage
            you to review this Privacy Notice frequently to be informed of how
            we are protecting your information.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </h2>
          <p className="mb-3 sm:mb-4">
            If you have questions or comments about this notice, you may contact
            our Data Protection Officer (DPO) by email at
            siddhant49kaushik@gmail.com, by phone at 9301789217, or by post at:
          </p>
          <address className="mb-3 sm:mb-4 text-sm sm:text-base">
            Acceron Industries India Private Limited
            <br />
            Data Protection Officer
            <br />
            63/A, INDUSTRIAL AREA, ROAD NO - 8, GOVINDPURA,
            <br />
            BHOPAL
            <br />
            BHOPAL, Madhya Pradesh 462023
            <br />
            India
          </address>
        </section>

        <section className="mb-8">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
            11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h2>
          <p className="mb-3 sm:mb-4">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            details about how we have processed it, correct inaccuracies, or
            delete your personal information. You may also have the right to
            withdraw your consent to our processing of your personal
            information. These rights may be limited in some circumstances by
            applicable law.
          </p>
          <p className="mb-3 sm:mb-4">
            To request to review, update, or delete your personal information,
            please fill out and submit a{" "}
            <a
              href="https://app.termly.io/notify/1e7647ca-85f0-4571-8561-0a73a7774788"
              className="text-blue-600 hover:underline"
            >
              data subject access request
            </a>
            .
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
}
