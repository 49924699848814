import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import FirstSection from "./FirstSection";
import SecondSection from "./Second-Section";
import ThirdSection from "./Third-Section";
import FourthSection from "./Fourth-Section";
import Footer from "../Footer";
import SEO from "../SEO";
import AboutFirstSection from "../About/First-Section";
import AboutSecondSection from "../About/Second-Section";
import ProductFirstSection from "../Product-Category/Product-Categories";
import ContactFirstSection from "../Contact-Us/First-Section";
import ContactSecondSection from "../Contact-Us/Second-Section";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        title="Home"
        description="Empowering Performance through Acceron's Cutting-Edge Lubrication Solutions"
        keywords="performance, acceron, company, lubrication, solutions"
        image="../../assets/images/Acceron/Home/First-Section/first-section-bg.png"
        url="https://www.acceron.co/"
      />
      <Navbar />
      <FirstSection />
      <SecondSection />
      {/* <ThirdSection /> */}
      <AboutFirstSection page="home" />
      <ProductFirstSection />
      <FourthSection />
      <ContactFirstSection />
      <ContactSecondSection />
      <Footer />
    </>
  );
}

export default Home;
