import "./index.scss";
import React, { useEffect, useState } from "react";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";

import Button from "@mui/material/Button";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";

function ContactSecondSection() {
  const [headingLine, setHeadingLine] = useState(HeadingLine);

  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  useEffect(() => {
    if (textColor === "light") {
      setHeadingLine(HeadingLine);
    } else {
      setHeadingLine(HeadingLineWhite);
    }
  }, [textColor]);

  return (
    <div
      className="container-fluid contact-information-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <div className="row contact-information-row-one">
        <div className="d-flex justify-content-start align-items-center fourth-section-heading">
          <h2 style={{ color: secondaryColor }}>Locations</h2>
          <img src={headingLine} alt="" className="headline-image" />
        </div>
      </div>
      <div className="row contact-information-row-two">
        <div className="col-lg-4 col-sm-12 contact-information-row-col">
          <h6 style={{ color: secondaryColor }}>
            Overseas <span>Office</span>
          </h6>
          <p style={{ color: secondaryColor }}>
            Address : 534-536, Block-B, <br /> Dubai Economy Building,
            <br /> Clock Tower, Deira
            <br />
            Dubai, United Arab Emirates
          </p>
        </div>
        <div className="col-lg-4 col-sm-12 contact-information-row-col">
          <h6 style={{ color: secondaryColor }}>
            Head <span>Office</span>
          </h6>
          <p style={{ color: secondaryColor }}>
            Address : 1-3-183/40/22/6B PLOT NO 41, P&T COLONY, GANDHI NAGAR,
            HYDERABAD, TELANGANA, <br />  500080.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12 contact-information-row-col">
          <h6 style={{ color: secondaryColor }}>
            Corporate <span>Office</span>
          </h6>
          <p style={{ color: secondaryColor }}>
            Address : 63/A, INDUSTRIAL AREA, <br /> ROAD NO - 8, GOVINDPURA,
            BHOPAL <br />
            462023
          </p>
        </div>
        <div className="col-lg-4 col-sm-12 contact-information-row-col">
          <h6 style={{ color: secondaryColor }}>
            Production <span> Facility</span>
          </h6>
          <p style={{ color: secondaryColor }}>
            Address : 16, SAKET INDUSTRIAL ESTATE, <br /> CHANGODAR, AHMEDABAD,{" "}
            <br /> 382213
          </p>
        </div>
        <div className="col-lg-4 col-sm-12 contact-information-row-col"></div>
        <div className="col-lg-4 col-sm-12 contact-information-row-col"></div>
      </div>
      <div className="row contact-information-row-two"></div>
      <div className="row contact-information-row-two"></div>
      {/* <div className="row contact-information-row-two">
        <h6>
          HYDERABAD <span> Office</span>
        </h6>
        <p>Address : 22-5-326, FB LANE, CHARMINAR EAST, HYDERABAD, 500002</p>
      </div> */}
      <div className="row contact-information-row-two"></div>
    </div>
  );
}

export default ContactSecondSection;
