import React, { useEffect, useState } from "react";
import "./index.scss";
import EmotionIcon from "../../assets/images/Malayali/First-Section/EMOTION.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

function FirstSection() {
  const [scrollY, setScrollY] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Calculate the height of the image element
    const imageElement = document.querySelector(".outer-video video");
    if (imageElement) {
      const height = imageElement.clientHeight;
      setImageHeight(height);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define animation variants with smoother transitions
  const fadeOut = {
    opacity: 0.8, // Darker opacity value
    transition: { duration: 0.2, ease: "easeInOut" },
  };

  const fadeIn = {
    opacity: 1,
    transition: { duration: 0.2, ease: "easeInOut" },
  };

  // Calculate the scroll threshold as 50% of the image height
  const scrollThreshold = imageHeight * 0.5;

  // Check if the scroll position is greater than or equal to the scroll threshold to trigger the fade-out and fade-in animations
  useEffect(() => {
    if (scrollY >= scrollThreshold) {
      controls.start(fadeOut);
    } else {
      controls.start(fadeIn);
    }
  }, [scrollY, controls, scrollThreshold]);

  return (
    <div className="row first-section-container">
      <div className="col-xs-12 first-image-main">
        <section className="outer-video">
          <motion.div
            initial="visible"
            animate={controls}
            variants={fadeOut as any} // Use the fadeOut variant for hiding
            // transition={{ duration: 0.5 }}
          >
            <video autoPlay muted playsInline loop>
              <source
                src="https://acceron-public.s3.ap-south-1.amazonaws.com/web-videos/acceron-home-video.mp4"
                type="video/mp4"
              />
            </video>
          </motion.div>
          <div className="first-section-text">
            <div>
              <h2>
                Empowering Performance through Acceron's Cutting-Edge
                Lubrication Solutions."
              </h2>
              <p>
                Discover peak performance with Acceron Industries. Our advanced
                lubrication solutions for engines, gears, and more redefine
                reliability and efficiency, driving your success forward.
              </p>
              <Link to={`/about_us`} className="no-text-decoration">
                <Button variant="contained">
                  <span>LEARN MORE</span>
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default FirstSection;
