import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import ContactSecondSection from "./Second-Section";
import ContactFirstSection from "./First-Section";
import SEO from "../SEO";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        title="ContactUs"
        description="To connect with us regarding Acceron, please don't hesitate to reach out."
        keywords="connect, reach out, contact"
        image="../../assets/images/Acceron/Header/acceron-logo.svg"
        url="https://www.acceron.co/contact_us"
      />
      <Navbar />
      <ContactFirstSection />
      <ContactSecondSection />
      <Footer />
    </>
  );
}

export default ContactUs;
