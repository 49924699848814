import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";

import Footer from "../Footer";
import GalleryFolder from "./Gallery-Folder";
import { Outlet, Route, Routes } from "react-router-dom";
import GalleryDetails from "./GalleryDetails";

function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Routes>
      <Route
        element={
          <>
            <Navbar />
            <Outlet />
            <Footer />
          </>
        }
      >
        <Route path="/list" element={<GalleryFolder />} />
        <Route path="details/:id" element={<GalleryDetails />} />
      </Route>
    </Routes>
  );
}

export default Gallery;
