import "./index.scss";
import React, { useEffect, useState } from "react";
import HeadingLine from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-black.png";
import HeadingLineWhite from "../../../assets/images/Acceron/Home/Fourth-Section/heading-line-white.png";
import Rajneeh from "../../../assets/images/Acceron/About/Screenshot 2023-08-30 at 1.19.png";
import Ashish from "../../../assets/images/Acceron/About/Screenshot 2023-08-30 at 1.19 (1).png";
import Rahul from "../../../assets/images/Acceron/About/Rahul.jpg";
import Nitesh from "../../../assets/images/Acceron/About/Nitesh.png";
import Chandresh from "../../../assets/images/Acceron/About/Chandresh.jpg";
import PictureOne from "../../../assets/images/Acceron/About/picture_one.png";
import PictureTwo from "../../../assets/images/Acceron/About/picture_two.png";
import PictureThree from "../../../assets/images/Acceron/About/picture_three.png";
import manoj_kaushik from "../../../assets/images/Acceron/About/manoj_kaushik.png";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { useTheme } from "../../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../../utils/colors";
import { getContrastColor } from "../../../../utils/ColorUtils";

function AboutSecondSection() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  const [headingLine, setHeadingLine] = useState(HeadingLine);

  useEffect(() => {
    if (textColor === "light") {
      setHeadingLine(HeadingLine);
    } else {
      setHeadingLine(HeadingLineWhite);
    }
  }, [textColor]);

  return (
    <div
      className="container-fluid contact-information-container about-information-container"
      style={{
        backgroundColor: primaryColor,
        color: textColor === "light" ? "#fff" : "#000",
      }}
    >
      <div className="row contact-information-row-one">
        <div className="d-flex justify-content-start align-items-center fourth-section-heading">
          <h2 style={{ color: secondaryColor }}>
            Our<span> Leadership</span>{" "}
          </h2>
          <img src={headingLine} alt="" className="img-fluid headline-image" />
        </div>
      </div>
      <div className="row about-heads-container d-md-flex d-block justify-content-center">
        <div className="col-xs-12 col-md-4 col-lg-4 ldship-col mb-3">
          <div className="ldship-card" style={{ borderColor: secondaryColor }}>
            <div className="row ldship-img-row">
              <img src={Rajneeh} className="ldship-image" />
            </div>
            <div className="row ldship-name-row">
              <h1>Mr. Rajneesh Yadav</h1>
            </div>
            <div className="row ldship-position-row">
              <h2 style={{ color: secondaryColor }}>Director</h2>
              <h2 style={{ color: secondaryColor }}>Sales and Marketing</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-4 col-lg-4 ldship-col mb-3">
          <div className="ldship-card" style={{ borderColor: secondaryColor }}>
            <div className="row ldship-img-row">
              <img src={Rahul} className="ldship-image" />
            </div>
            <div className="row ldship-name-row">
              <h1>Mr. Rahul Kaushik</h1>
            </div>
            <div className="row ldship-position-row">
              <h2 style={{ color: secondaryColor }}>CEO</h2>
              <h2>
                <span>.</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-4 col-lg-4 ldship-col mb-3">
          <div className="ldship-card" style={{ borderColor: secondaryColor }}>
            <div className="row ldship-img-row">
              <img src={manoj_kaushik} className="ldship-image" />
            </div>
            <div className="row ldship-name-row">
              <h1>Mr. Manoj Kaushik</h1>
            </div>
            <div className="row ldship-position-row">
              <h2 style={{ color: secondaryColor }}>Director</h2>
              <h2 style={{ color: secondaryColor }}>Godown Channel Sales</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-4 col-lg-4 ldship-col mb-3">
          <div className="ldship-card" style={{ borderColor: secondaryColor }}>
            <div className="row ldship-img-row">
              <img src={Chandresh} className="ldship-image" />
            </div>
            <div className="row ldship-name-row">
              <h1>Mr. Chandresh</h1>
            </div>
            <div className="row ldship-position-row">
              <h2 style={{ color: secondaryColor }}>Director</h2>
              <h2 style={{ color: secondaryColor }}>Logistics & Commerce</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-4 col-lg-4 ldship-col mb-3">
          <div className="ldship-card" style={{ borderColor: secondaryColor }}>
            <div className="row ldship-img-row">
              <img src={Nitesh} className="ldship-image" />
            </div>
            <div className="row ldship-name-row">
              <h1>Mr. Nitesh Kumar Jain</h1>
            </div>
            <div className="row ldship-position-row">
              <h2 style={{ color: secondaryColor }}>Director</h2>
              <h2 style={{ color: secondaryColor }}>Manufacturing Unit</h2>
            </div>
          </div>
        </div>

        {/* <div className="col-xs-12 col-md-4 col-lg-4 ldship-col mb-3">
          <div className="ldship-card" style={{ borderColor: secondaryColor }}>
            <div className="row ldship-img-row">
              <img src={Rahul} className="ldship-image" />
            </div>
            <div className="row ldship-name-row">
              <h1>Mr. Rahul Kaushik</h1>
            </div>
            <div className="row ldship-position-row">
              <h2 style={{ color: secondaryColor }}>Chief Marketing Officer</h2>
              <h2>
                <span>.</span>
              </h2>
            </div>
          </div>
        </div> */}
      </div>

      <div className="row about-heads-container d-md-flex d-block justify-content"></div>

      {/* <div className="row gallery-row-one">
        <div className="d-flex justify-content-start align-items-center fourth-section-heading">
          <h2>Gallery</h2>
          <img src={HeadingLine} alt="" />
          <Button
            variant="outlined"
            className="filter-cat-pro gallery-view-all-btn"
          >
            View All
          </Button>
        </div>
      </div>
      <div className="row gallery-row"></div> */}
      <div className="">
        <div className="d-flex justify-content-between align-items-center aboutus-gallery-main">
          <div className="d-flex justify-content-start align-items-center fourth-section-heading aboutus-gallery-heading">
            <h2 style={{ color: secondaryColor }}>Gallery</h2>
            {textColor === "light" ? (
              <img
                src={HeadingLine}
                alt=""
                className="img-fluid headline-image"
              />
            ) : (
              <img
                src={HeadingLineWhite}
                alt=""
                className="img-fluid headline-image"
              />
            )}
          </div>
          <Link to={"/gallery/list"}>
            <Button
              variant="outlined"
              className="about-viewall-btn"
              style={{ color: secondaryColor, borderColor: secondaryColor }}
            >
              View All
            </Button>
          </Link>
        </div>
        <div className="d-md-flex d-block justify-content-center align-items-center about-gallery-container">
          <Card className="mb-3">
            <img src={PictureOne} alt="" />
          </Card>
          <Card className="mb-3">
            <img src={PictureTwo} alt="" />
          </Card>
          <Card className="mb-3">
            <img src={PictureThree} alt="" />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AboutSecondSection;
